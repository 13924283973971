/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { Routes } from './router'
import { BrowserRouter } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import Cookies from 'js-cookie'
import { envConfig } from './config'

export default class App extends React.Component {
  constructor (props) {
    super(props)
    this.idleTimer = 5
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }

  render () {
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 15}
          // onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          // onAction={this.handleOnAction}
          debounce={250}
        />
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    )
  }

  handleOnIdle (event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
    Cookies.remove('auth_token', { path: '/', domain: envConfig.domain })
    window.location.href = `${envConfig.baseUrlPublic}/ingresar`
  }
}

// const App = () => {
//   // const time = Number(new Date())
//   // console.log('time', time)
//   // useEffect(() => {
//   //   console.log('asd')
//   // }, [time])

//   return (
//     <BrowserRouter>
//       <Routes />
//     </BrowserRouter>
//   )
// }

// export default App
