import React from 'react'

function Loading ({ className = '', size = 19 }) {
  return (
    <div class={`spinner-border ${className}`} style={{ height: size, width: size }} role='status'>
      <span class='sr-only'>Loading...</span>
    </div>
  )
}

export default Loading
