/*eslint-disable */
import React, { useContext } from 'react'
import { Context } from '../../../../Context'
import { BtnTratamiento } from '../../../btn-tratamiento'


export const Notice = () => {
  const { nextApplying, producto } = useContext(Context)

  const getTextByProduct = () => {
    console.log(producto)
    if (producto && producto.id === 18) {
      return 'toma'
    }

    return 'aplicación'
  }

  const showNoti = !!nextApplying;
  return (
    <>
      { showNoti ?
        <div className='subheader-aviso bg-primary text-white d-flex justify-content-center align-items-center p-2'>
          <p className='font-size-base mb-0'>¿Confirmaste la {getTextByProduct()} de medicación del dia de la fecha?</p>
          <BtnTratamiento className="btn btn-aviso font-size-base mx-5"/>
        </div> : null}
    </>
  )
}
