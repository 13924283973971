/* global fetch */

const parseResultByContent = (response) => {
  const contentType = response.headers.get('content-type')
  if (contentType && contentType.includes('application/json')) {
    return response.json()
  }
  return response.text()
}
export const request = async (url, option) => {
  const response = await fetch(url, option)
  const data = await parseResultByContent(response)
  if (response.ok) {
    return data
  } else {
    throw data
  }
}
