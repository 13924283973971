import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { Overlay, Tooltip } from 'react-bootstrap'
import './styles.scss'

function MultiFile ({ maxInputs = 5, onChange, title = '' }) {
  maxInputs = Number(maxInputs) - 1
  const [buttonAddBlocked, setButtonAddBlocked] = useState(false)
  const [orders, setOrders] = useState([null])
  const [show, setShow] = useState(false)
  const target = useRef(null)

  const onChangeInputFile = e => {
    const index = Number(e.target.name.split('-')[1])
    const newOrders = [...orders]
    newOrders[index] = e.target.files[0].name
    setOrders(newOrders)
    doCallback(newOrders)
  }

  const addOrder = () => {
    setOrders([
      ...orders,
      null
    ])
    orders.length >= maxInputs && setButtonAddBlocked(true)
  }

  const removeOrder = indexToDelete => {
    const newOrders = orders.filter((order, index) => index !== indexToDelete)
    setOrders(newOrders)
    setButtonAddBlocked(false)
    doCallback(newOrders)
  }

  const doCallback = (newOrders) => {
    const files = newOrders.map((order, index) => {
      if (order) {
        const file = document.getElementById(`order-${index}`).files[0]
        console.log(file)
        return file
      }
      return null
    })
    onChange(files)
  }

  return (
    <div class='form-group mb-0'>
      <label>{title} </label>

      <button type='button' class='btn btn-link p-0 ml-2' ref={target} onClick={() => setShow(!show)}><FontAwesomeIcon icon={faQuestionCircle} /></button>
      <Overlay target={target.current} show={show} placement='right'>
        {(props) => (
          <Tooltip id='overlay-example' {...props}>
            Puedes agregar nuevos campos y adjuntar los archivos. <br /><br />
            Elimina los archivos presionando el boton rojo
          </Tooltip>
        )}
      </Overlay>

      {
        orders.map((order, index) => {
          return (
            <div class='input-group mb-3' key={index}>
              <div class='input-group-prepend'>
                <button class='btn btn-outline-danger btn-sm pt-0 pb-0' onClick={() => removeOrder(index)} type='button'><FontAwesomeIcon icon={faTrashAlt} /></button>
              </div>
              <div class='custom-file'>
                <input id={`order-${index}`} name={`order-${index}`} onChange={onChangeInputFile} type='file' class='custom-file-input' />
                <label class='custom-file-label' for='customFile'> {order || 'Seleccione un archivo'} </label>
              </div>
            </div>
          )
        })
        }
      <div className='row'>
        <div className='col-md-12'>
          <button type='button' style={{ padding: 5 }} class='btn btn-outline-primary float-right' disabled={buttonAddBlocked} onClick={addOrder}> Agregar </button>
        </div>
      </div>
    </div>
  )
}

export default MultiFile
