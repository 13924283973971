import React from 'react'
import ReactPaginate from 'react-paginate'
import './styles.scss'

export const Pagination = (props) => {
  return (
    <ReactPaginate
      previousLabel=''
      nextLabel=''
      breakClassName='break-me'
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      containerClassName='pagination'
      activeClassName='active'
      {...props}
    />
  )
}
