import * as moment from 'moment'
export const transformCalendar = ({ calendarioAplicaciones, proximaAplicacion }, productoId) => {
  const listCalendar = calendarioAplicaciones.map((item) => ({
    allDay: '',
    title: 'Última toma',
    id: item.id,
    extendedProps: {
      tooltip: 'Tomada',
      description: getText(item.realizada, productoId),
      id: item.id,
      hora: moment(item.fecha).format('HH'),
      isAlreadyTake: item.realizada ? 'si' : 'no'
    },
    start: item.fecha,
    end: item.fecha,
    className: 'calendario__event'
  }))
  if (proximaAplicacion) {
    listCalendar.push({
      allDay: '',
      title: 'Próxima toma',
      id: proximaAplicacion.id,
      extendedProps: {
        tooltip: 'Falta Tomar',
        description: `Próxima ${productoId === 18 ? 'toma' : 'aplicación'}`,
        id: proximaAplicacion.id,
        hora: moment(proximaAplicacion.fecha).format('HH'),
        isAlreadyTake: 'next'
      },
      start: proximaAplicacion.fecha,
      end: proximaAplicacion.fecha,
      className: 'calendario__event'
    })
  }
  return listCalendar
}

function getText (realizada, productoId) {
  let text = ''

  if (!realizada) {
    text = 'no '
  }

  if (productoId && (productoId === 18)) {
    text = text + 'tomada'
  } else {
    text = text + 'aplicada'
  }

  return text.charAt(0).toUpperCase() + text.slice(1)
}
