import React from 'react'

export const CardItem = (props) => {
  return (
    <div className='col-md-4 col-xxl-4 col-lg-12 item-material'>
      <div className='card card-custom card-shadowless'>
        <div className='card-body p-0'>
          <div className='overlay'>
            <div className='overlay-wrapper rounded bg-light text-center p-5'>
              <img src={props.urlFileImage} alt='' />
            </div>
            <div className='overlay-layer'>
              <a title='Descargar' href={props.urlFile} download className='btn font-weight-bolder btn-sm btn-primary mr-2'><i className='fas fa-download icon-2x' /></a>
              <a title='Link Externo' href={props.urlFile} target='_blank' rel='noreferrer' className='btn font-weight-bolder btn-sm btn-primary mr-2'><i className='fas fa-external-link-alt icon-2x' /></a>
            </div>
          </div>
          <div className='text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column'>
            <a href='#' className='font-size-h5 font-weight-bolder text-dark-75 text-hover-primary mb-1'>{props.title}</a>
            <span className='font-size-lg'>{props.description}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
