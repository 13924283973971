import api from '../util/Api'
// const dataMock = [
//   {
//     urlFile: '/material-educativo/Servicio-enfermería-a-domicilio-Benlysta-IV.jpeg',
//     urlFileImage: '/material-educativo/benlistaiv.png',
//     title: '',
//     description: 'Servico enfermeria a domicilio Benlysta IV',
//     product: ['benlysta iv', 'benlysta'],
//     type: 'file'
//   },
//   {
//     urlFile: '/material-educativo/Servicio-enfermería-a-domicilio-Benlysta-SC.pdf',
//     urlFileImage: 'https://gsk-private.elpixel.net/material-educativo/material-educativo/benlistasc.png',
//     title: '',
//     description: 'Servico enfermeria a domicilio Benlysta CS',
//     product: ['benlysta iv', 'benlysta'],
//     type: 'file'
//   },
//   {
//     urlFile: 'https://gsk-private.elpixel.net/material-educativo/material-educativo/Servicio-enfermería-a-domicilio-Nucala.jpeg',
//     urlFileImage: 'https://gsk-private.elpixel.net/material-educativo/material-educativo/nucala.png',
//     title: '',
//     description: 'servicio enfermeria a domicilio Nucala',
//     product: ['nucala'],
//     type: 'file'
//   },
//   {
//     urlFile: 'https://gsk-private.elpixel.net/material-educativo/material-educativo/Programa-MAS-servicios.pdf',
//     urlFileImage: 'https://gsk-private.elpixel.net/material-educativo/material-educativo/MAS-preview.png',
//     title: '',
//     description: 'Programa MAS servicios',
//     product: [],
//     type: 'file'
//   },
//   {
//     urlFile: 'https://gsk-private.elpixel.net/material-educativo/material-educativo/Mecanismo-de-accion.mp4',
//     urlFileImage: 'https://gsk-private.elpixel.net/material-educativo/material-educativo/nucala-video.png',
//     title: '',
//     description: 'Mecanismo de accion - NUCALA',
//     product: [],
//     type: 'video'
//   }
// ]

export const materialEducativo = {
  async getByFilter (text = null, tipoArchivo = null, patologia = null) {
    let conditions = ''

    if (tipoArchivo !== null) {
      conditions = conditions + `&tipo=${tipoArchivo}`
    }

    if (text !== null) {
      conditions = conditions + `&texto=${text}`
    }
    console.log('url', `/materiales?patologia=${patologia}${conditions}`)
    const response = await api.get(`/materiales?patologia=${patologia}${conditions}`)
    return response
  }
}
