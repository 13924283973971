import React, { useState, useContext, useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { Context } from '../../Context'
import * as moment from 'moment'
import { useForm } from 'react-hook-form'
import './styles.scss'

const excusasOptions = [
  {
    label: 'Demora en la entrega',
    value: 'Demora en la entrega'
  },
  {
    label: 'Demora en la presentación',
    value: 'Demora en la presentación'
  },
  {
    label: 'Situación personal',
    value: 'Situación personal'
  },
  {
    label: 'Efectos secundarios',
    value: 'Efectos secundarios'
  },
  {
    label: 'Otras patologias',
    value: 'Otras patologias'
  },
  {
    label: 'COVID (Criterio médico)',
    value: 'COVID'
  }
]

export const ModalTratamiento = ({ onClose, onRegister = () => { } }) => {
  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' })

  const [state, setstate] = useState({
    modalRason: false,
    modalRegister: true,
    loading: false
  })
  const { nextApplying, updateTakeMedication, producto } = useContext(Context)
  const handleShowRegister = () => {
    setstate({
      ...state,
      modalRason: true,
      modalRegister: false
    })
  }

  const getTextByProduct = () => {
    console.log(producto)
    if (producto && (producto.id === 18)) {
      return 'tomó'
    }

    return 'aplicó'
  }

  const registrarExcusa = (data) => {
    onRegister(true)
    updateTakeMedication({
      taken: false,
      takenDate: moment(nextApplying).format('YYYY-MM-DD'),
      reason: (data && data.excusa) || 'reason not found'
    })
  }

  const registrarToma = () => {
    onRegister(true)
    updateTakeMedication({
      taken: true,
      takenDate: moment(nextApplying).format('YYYY-MM-DD')
    })
  }

  const dateNextApplying = useMemo(() => {
    const formatedDate = moment(nextApplying).format('DD-MMMM-YYYY')
    const splitDate = formatedDate.split('-')
    return `${splitDate[0]} de ${splitDate[1]} de ${splitDate[2]}`.toUpperCase()
  }, [])

  return (
    <>
      <Modal show={state.modalRegister}>
        <Modal.Header>
          <h3 className='font-weight-boldest font-size-h2 text-uppercase text-center w-100 mb-0' id='eventModalLabel'>¿{getTextByProduct()} su medicación?</h3>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className='px-13'>
          <p>Día: {dateNextApplying}</p>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-primary font-size-h6' data-dismiss='modal' disabled={state.loading} onClick={registrarToma}>Si</button>
          <button type='button' className='btn btn-secondary font-size-h6' data-dismiss='modal' disabled={state.loading} onClick={handleShowRegister}>No</button>
          <button type='button' className='btn btn-secondary font-size-h6' data-dismiss='modal' disabled={state.loading} onClick={onClose}>Recordar mas tarde</button>
        </Modal.Footer>
      </Modal>
      <Modal show={state.modalRason}>
        <Modal.Header>
          <h3 className='font-weight-boldest font-size-h2 text-uppercase text-center w-100 mb-0' id='noMedicacionLabel'>¿Por qué no tomó su medicación?</h3>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true'>&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form id='form-tratamiento' onSubmit={handleSubmit(registrarExcusa)}>
            <div className='form-group mb-0'>
              <div className='checkbox-list ml-8'>
                {excusasOptions.map((item, index) => {
                  return (
                    <label className='modal-tratamiento__group' key={index}>
                      <input className='modal-tratamiento__inputRadio' ref={register({ required: true })} type='radio' name='excusa' value={item.value} />
                      <span>{item.label}</span>
                    </label>
                  )
                })}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn btn-secondary font-size-h6' disabled={state.loading} onClick={onClose}>Cancelar</button>
          <button type='submit' form='form-tratamiento' disabled={state.loading || !formState.isValid} className='btn btn-primary font-size-h6'>Enviar</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
