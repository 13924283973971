import { Link, useRouteMatch } from 'react-router-dom'
export const CusmtomLink = ({
  label, to, activeOnlyWhenExact, onClick = () => { }
}) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  })
  return (
    <li className={`menu-item menu-item-submenu menu-item-rel ${match ? 'menu-item-open menu-item-here menu-item-active' : ''}`}>
      <Link to={to} className='menu-link' onClick={onClick}>
        <span className='menu-text'>{label}</span>
      </Link>
    </li>
  )
}
