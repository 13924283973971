import React, { useState, useEffect } from 'react'
import Api from '../../util/Api'
import Loading from '../../components/Loading'
import { Modal } from 'react-bootstrap'
import { envConfig } from '../../config'

const Apoyo = () => {
  const [sending, setSending] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [buttonBlocked, setButtonBlocked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(async function () {
    setLoading(true)
    const userData = await Api.get('/pacientes/me')
    setUser(userData || {})
    setLoading(false)
  }, [])

  const handleForm = async () => {
    setSending(true)
    setButtonBlocked(true)
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'multipart/form-data; boundary=--------------------------173375425196423528230954')

    const formdata = new FormData()
    const payload = {
      Token: user.pharmexToken,
      IdPacientePrograma: user.idPacientePrograma,
      TipoActividad: 'Asesoramiento'
    }

    formdata.append('SolicitarActividadRequest', JSON.stringify(payload))

    const requestOptions = {
      method: 'POST',
      headers: undefined,
      body: formdata,
      redirect: 'follow'
    }

    fetch(envConfig.apiPharmexUrl + '/pacienteprograma/actividad', requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        setShowModal(true)
        setShowModalConfirmation(false)
        setSending(false)
      })
  }

  const toggleModalConfirmation = e => {
    setShowModalConfirmation(!showModalConfirmation)
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  if (loading) {
    return (
      <div class='d-flex flex-column-fluid py-15 h-1000px' style={{ backgroundColor: '#eef0f8' }}>
        <div class='container'>
          <div class='d-flex flex-row'>
            <Loading size='30' className='ml-auto mr-auto' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='d-flex flex-column-fluid py-15' style={{ backgroundColor: '#eef0f8' }}>
      <div className='container'>
        <div className='card card-custom' id='aplicacionCentros'>
          <div className='card-header'>
            <h3 className='card-title'>Consultar apoyo y seguimiento </h3>
          </div>
          <form className='form' name='kt_centros_aplicacion'>
            <div className='card-body'>
              <p className='mb-8'>Para poder solicitar apoyo y seguimiento, haga click en Solicitar en el siguiente formulario y será contactado por un asesor</p>
              <div class='form-group form-group-last'>
                <div class='alert alert-custom alert-default' role='alert'>
                  <div class='alert-icon'><i class='flaticon-warning text-primary' /></div>
                  <div class='alert-text'>
                    Si tu médico tratante, llega a cambiar el medicamento y/o Presentación , por favor solicitar que un asesor del programa se contacte con usted
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <label>Nombre</label>
                <input disabled name='nombre' id='nombre' type='text' className='form-control form-control-lg' placeholder='Nombre' value={`${user && user.nombre}`} required />
              </div>
              <div className='form-group'>
                <label>Apellido</label>
                <input disabled name='apellido' id='apellido' type='text' className='form-control form-control-lg' placeholder='Apellido' value={`${user && user.apellido}`} required />
              </div>
              <div className='form-group mb-0'>
                <label>DNI</label>
                <input disabled name='dni' id='dni' type='text' className='form-control form-control-lg' placeholder='DNI' value={`${user && user.nroDocumento}`} required />
              </div>

            </div>
            <div className='card-footer'>
              <button type='button' onClick={toggleModalConfirmation} className='btn btn-primary mr-2' disabled={buttonBlocked}>Solicitar</button>
              <p class='mt-5'>
                Acompañamiento y recordatorio en la aplicación de su medicación.
              </p>
            </div>
            <Modal show={showModalConfirmation} onHide={toggleModalConfirmation} size='lg'>
              <Modal.Header>
                <h3 className='font-weight-boldest font-size-h2 text-uppercase text-center w-100 mb-0' id='eventModalLabel'>Solicitud para apoyo y seguimiento</h3>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className='px-13 text-center'>
                <p> Al confirmar, enviará una solicitud para apoyo y seguimiento. </p>
                <p> ¿Desea confirmar? </p>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' className='btn btn-primary font-size-h6' data-dismiss='modal' onClick={handleForm}>
                  {sending ? <Loading className='ml-2 mr-2' /> : 'Confirmar'}
                </button>
                <button type='button' className='btn btn-secondary font-size-h6' data-dismiss='modal' onClick={toggleModalConfirmation}>Cancelar</button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={toggleModal} size='lg'>
              <Modal.Header>
                <h3 className='font-weight-boldest font-size-h2 text-uppercase text-center w-100 mb-0' id='eventModalLabel'>Solicitud para apoyo y seguimiento</h3>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className='px-13 text-center'>
                <p><strong>Su solicitud para apoyo y seguimiento se envió con éxito.</strong></p>
                <p> A la brevedad nos comunicaremos con usted para confirmar la solicitud.</p>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' className='btn btn-primary font-size-h6' data-dismiss='modal' onClick={toggleModal}>Volver</button>
              </Modal.Footer>
            </Modal>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Apoyo
