import React, { useContext } from 'react'
import 'moment/locale/es'
import { BtnTratamiento } from '../../btn-tratamiento'
import { Context } from '../../../Context'

const moment = require('moment')

function Reminder ({ nextMedicine = undefined }) {
  const { producto } = useContext(Context)

  const getTextByProduct = () => {
    if (producto && producto.id === 18) {
      return 'toma'
    }

    return 'aplicación'
  }

  let textPlural = 'aplicaciones'
  if (producto && (producto.id === 18)) {
    textPlural = 'tomas'
  }

  let formatedDate

  if (nextMedicine && nextMedicine.fecha) {
    formatedDate = moment(nextMedicine.fecha).format('DD-MMMM-YYYY')

    formatedDate = `${formatedDate.split('-')[0]} de ${formatedDate.split('-')[1]} de ${formatedDate.split('-')[2]}`.toUpperCase()
  }

  return (
    <div class='d-flex flex-wrap'>
      <h4 class='font-weight-boldest w-100 mb-10'>RECORDATORIOS</h4>
      <div class='doctor-image d-flex justify-content-center align-items-center w-80px h-80px display1 text-muted mr-7'>
        <ReminderIcon />
      </div>
      <div class='d-flex align-items-center'>
        {
           formatedDate &&
             <div class='d-flex align-items-center justify-content-between flex-wrap'>
               <div class='mr-3' id='proximaToma'>
                 <span class='d-flex align-items-center text-dark font-size-h3 mr-3 my-2'>Recordá que tu próxima {getTextByProduct()} es el día:</span>
                 <div class='d-flex flex-wrap'>
                   <span class='text-primary font-size-h3 mr-lg-8 mr-5 mb-lg-0 mb-2 font-weight-boldest'>
                     {formatedDate}
                   </span>
                 </div>
                 <BtnTratamiento className='btn fc-list-button mt-10' />
               </div>
             </div>
       }

        {
           !formatedDate &&
             <ReminderPlaceholder textPlural={textPlural} />
       }
      </div>
    </div>
  )
}

const ReminderPlaceholder = ({ textPlural }) => {
  return (
    <>
      <span>

        <h5>Aún no tienes tomas disponibles</h5>
        <p style={{ fontStyle: 'italic', fontSize: '1em' }}>Aquí aparecerá un resumen de tus {textPlural} cuando estén disponibles</p>
      </span>
    </>
  )
}

const ReminderIcon = () => {
  return (
    <span class='svg-icon svg-icon-primary svg-icon-6x d-flex align-items-center justify-content-center'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 78.21 86.09'>
        <g id='Layer_2' data-name='Layer 2'>
          <g id='Layer_1-2' data-name='Layer 1'>
            <path d='M60.73,14.4l2-3.45c.43-.78-.6-2.2-2.31-3.18S57,6.61,56.5,7.37L54.42,11a38.35,38.35,0,0,0-10-2.76V2.37h0C44.36,1.06,42,0,39.11,0s-5.26,1.06-5.28,2.37h0V8.23a38.35,38.35,0,0,0-10,2.76L21.71,7.37c-.46-.76-2.2-.59-3.91.4s-2.74,2.4-2.31,3.18l2,3.45a39.11,39.11,0,1,0,43.25,0m-21.62,66A33.41,33.41,0,1,1,72.52,47,33.41,33.41,0,0,1,39.11,80.39' style={{ fill: '#F36633' }} />
            <line x1='39.11' y1='46.98' x2='58.25' y2='27.83' style={{ fill: '#F36633' }} />
            <path d='M39.11,47.7a.73.73,0,0,1-.52-.21.72.72,0,0,1,0-1L57.74,27.32a.72.72,0,0,1,1,1L39.62,47.49a.73.73,0,0,1-.51.21' style={{ fill: '#F36633' }} />
            <path d='M26.12,23.46a1.74,1.74,0,1,1-1.74-1.74,1.74,1.74,0,0,1,1.74,1.74' style={{ fill: '#F36633' }} /><path d='M26.12,70.38a1.74,1.74,0,1,1-1.74-1.74,1.74,1.74,0,0,1,1.74,1.74' style={{ fill: '#F36633' }} />
            <path d='M55.3,23.46a1.74,1.74,0,1,1-1.74-1.74,1.74,1.74,0,0,1,1.74,1.74' style={{ fill: '#F36633' }} /><path d='M55.3,70.38a1.74,1.74,0,1,1-1.74-1.74,1.74,1.74,0,0,1,1.74,1.74' style={{ fill: '#F36633' }} />
            <path d='M16.7,33.3A1.74,1.74,0,1,1,15,31.56,1.74,1.74,0,0,1,16.7,33.3' style={{ fill: '#F36633' }} /><path d='M65,33.29a1.74,1.74,0,1,1-1.74-1.74A1.74,1.74,0,0,1,65,33.29' style={{ fill: '#F36633' }} />
            <path d='M16.7,60.51A1.74,1.74,0,1,1,15,58.78a1.74,1.74,0,0,1,1.73,1.73' style={{ fill: '#F36633' }} /><path d='M65,60.51a1.74,1.74,0,1,1-1.74-1.74A1.74,1.74,0,0,1,65,60.51' style={{ fill: '#F36633' }} />
            <path d='M13.24,47a1.74,1.74,0,1,1-1.74-1.74A1.74,1.74,0,0,1,13.24,47' style={{ fill: '#F36633' }} /><path d='M68.45,47a1.74,1.74,0,1,1-1.74-1.74A1.74,1.74,0,0,1,68.45,47' style={{ fill: '#F36633' }} />
            <path d='M40.84,74.47a1.74,1.74,0,1,1-1.74-1.74,1.74,1.74,0,0,1,1.74,1.74' style={{ fill: '#F36633' }} /><path d='M40.84,19.37a1.74,1.74,0,1,1-1.74-1.73,1.74,1.74,0,0,1,1.74,1.73' style={{ fill: '#F36633' }} />
          </g>
        </g>
      </svg>
    </span>
  )
}

export default Reminder
