import React, { useState, useEffect } from 'react'
import { CardItem } from './components/card-item'
import './styles.scss'
import { Filter } from './components/filter'
import { Hero } from './components/hero'
// import { Pagination } from '../../components/pagination'
import { pacienteService } from '../../services/paciente.service'
import { materialEducativo } from '../../services/material-educativo.service'
import Loading from '../../components/Loading'

const fileType = [
  {
    value: 1,
    label: 'Archivo'
  }, {
    value: 2,
    label: 'Video'
  }, {
    value: 3,
    label: 'Documento'
  }
]

export const MaterialesEducativos = () => {
  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState([])
  const [pacienteProducto, setPacienteProducto] = useState({})

  const [state, setstate] = useState({
    listFile: [],
    nameProduct: '',
    row: 0,
    page: 0
  })

  const onSubmit = async (e) => {
    try {
      setLoading(true)
      if (e.typeFile === '') {
        e.typeFile = null
      }

      if (e.word === '') {
        e.word = null
      }

      const respose = await materialEducativo.getByFilter(e.word, e.typeFile, pacienteProducto.id)
      setFiles(respose)
      setLoading(false)
    } catch (_) {
    }
  }

  // const handlePageClick = ({ selected }) => {
  //   console.log(selected)
  // }

  useEffect(async () => {
    try {
      setLoading(true)
      const { producto } = await pacienteService.getPrograma()
      setPacienteProducto(producto)
      const respose = await materialEducativo.getByFilter(null, null, producto.id)
      setFiles(respose)
      setstate({ ...state, nameProduct: producto.nombre })
      setLoading(false)
    } catch (_) {
    }
  }, [])

  // if (loading) {
  //   return (
  //     <div class='d-flex flex-column-fluid py-15 h-1000px' style={{ backgroundColor: '#eef0f8' }}>
  //       <div class='container'>
  //         <div class='d-flex flex-row'>
  //           <Loading size='30' className='ml-auto mr-auto' />
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className='d-flex flex-column-fluid py-15 MaterialesEducativos'>
      <div className='container'>
        <div className='d-flex flex-row'>
          <div className='flex-row-fluid ml-lg-8'>
            <div className='card card-custom card-stretch gutter-b'>
              <div className='card-body'>
                <Hero />
                <Filter onSubmit={onSubmit} listTypeFile={fileType} />
                {
                  loading &&
                    <div class='d-flex flex-row'>
                      <Loading size='30' className='ml-auto mr-auto' />
                    </div>
                }

                {
                  !loading &&
                    <div className='mb-11'>
                      <div className='row'>
                        {files.map((item, index) => <CardItem key={index} {...item} />)}
                      </div>
                    </div>
                }

                {
                  files.length === 0 && !loading &&
                    <div className='row'>
                      <h4 className='ml-auto mr-auto'>No se encontraron materiales</h4>
                    </div>
                }

                {/* <Pagination onPageChange={handlePageClick} pageCount={state.row} forcePage={state.page} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
