import React from 'react'

export const Row = ({ title, description, href }) => {
  return (
    <tr>
      <td className='pl-0 pt-0 py-5' width='45'>
        <div className='symbol symbol-45 symbol-light-primary mr-2'>
          <span className='symbol-label'>
            <span className='svg-icon svg-icon-2x svg-icon-primary'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72.28 72.28'><g id='Layer_2' data-name='Layer 2'><g id='Layer_1-2' data-name='Layer 1'><path d='M23.57,40.2,1.78,62v0a6,6,0,1,0,8.51,8.52l21.8-21.8A29.65,29.65,0,0,1,23.57,40.2ZM48.19,0A24.1,24.1,0,1,0,72.28,24.09,24.1,24.1,0,0,0,48.19,0Zm0,40.66A16.57,16.57,0,1,1,64.75,24.09,16.57,16.57,0,0,1,48.19,40.66Z' style={{ fill: '#ec6737' }} /></g></g></svg>
            </span>
          </span>
        </div>
      </td>
      <td className='pl-0'>
        <a href={href} className='text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg'>{title}</a>
        <span className='text-muted font-weight-bold d-block'>{description}</span>
      </td>
      <td className='text-right pr-0'>
        <a href={href} className='btn btn-icon btn-light btn-sm'>
          <span className='svg-icon svg-icon-md svg-icon-primary'>
            <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <polygon points='0 0 24 0 24 24 0 24' />
                <rect fill='#000000' opacity='0.3' transform='translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)' x='11' y='5' width='2' height='14' rx='1' />
                <path d='M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z' fill='#000000' fillRule='nonzero' transform='translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)' />
              </g>
            </svg>
          </span>
        </a>
      </td>
    </tr>
  )
}
