/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { envConfig } from '../../config'

export const User = () => {
  const [show, setShow] = useState(false)
  const showStyle = {
    position: 'absolute',
    transform: 'translate3d(-209px, 47px, 0px)',
    top: 0,
    left: 0,
    willChange: 'transform'
  }

  const closeSession = () => {
    Cookies.remove('auth_token', { path: '/', domain: envConfig.domain })
    window.location.href = `${envConfig.baseUrlPublic}/ingresar`
  }

  return (
    <div className='topbar'>
      <div className='topbar-item'>
        <div className={`dropdown dropdown-inline ${show && 'show'}`}>
          <button onClick={() => setShow(!show)} className='btn btn-icon btn-icon-mobile btn-light w-auto d-flex align-items-center btn-lg px-2' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
            <span className='svg-icon svg-icon-primary svg-icon-2x'>
              <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
                <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                  <polygon points='0 0 24 0 24 24 0 24' />
                  <path d='M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z' fill='#000000' fillRule='nonzero' opacity='0.3' />
                  <path d='M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z' fill='#000000' fillRule='nonzero' />
                </g>
              </svg>
            </span>
          </button>
          <div className={`dropdown-menu p-0 mt-2 mx-0 dropdown-menu-md dropdown-menu-right ${show && 'show'}`} style={show ? showStyle : {}}>
            <ul className='navi navi-hover'>
              <li className='navi-item'>
                <Link to='/perfil' className='navi-link'>
                  <span className='icon-gray icon-lg text-center mr-3'>
                    <i className='fas fa-user' />
                  </span>
                  <span className='navi-text'>Perfil</span>
                </Link>
              </li>
              <li className='navi-item'>
                <a href='#' onClick={closeSession} className='navi-link'>
                  <span className='icon-gray icon-lg text-center mr-3'>
                    <i className='fas fa-sign-out-alt' />
                  </span>
                  <span className='navi-text'>Salir</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
