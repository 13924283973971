import React from 'react'

const IconNew = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 80'>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <path d='M40,0A40,40,0,1,0,80,40,40,40,0,0,0,40,0Zm0,77A37,37,0,1,1,77,40,37,37,0,0,1,40,77ZM40,9A31,31,0,1,0,71,40,31,31,0,0,0,40,9Zm0,56A25,25,0,1,1,65,40,25,25,0,0,1,40,65ZM38.51,51.61a4.06,4.06,0,0,0-4.2,4.25A3.06,3.06,0,0,0,37.61,59c2.28,0,4.3-1.54,4.3-3.77S40.69,51.61,38.51,51.61Zm-2.07-3.35h5.15C42.18,44.7,45.26,24,45.69,21H38.94Z' style={{ fill: '#ec6635' }} />
      </g>
    </g>
  </svg>
)

const IconOk = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72.28 72.28'>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <path d='M36.14,0A36.14,36.14,0,1,0,72.28,36.14,36.14,36.14,0,0,0,36.14,0Zm0,66.26A30.12,30.12,0,1,1,66.26,36.14,30.15,30.15,0,0,1,36.14,66.26ZM50,23.36,30.82,42.53,22.29,34A3,3,0,0,0,18,38.27L28.69,48.92a3,3,0,0,0,4.26,0l21.29-21.3A3,3,0,0,0,50,23.36Z' style={{ fill: '#d8dcdf' }} />
      </g>
    </g>
  </svg>
)

export const NotificationItem = ({ isNew, title = '', summary = '', link = '/' }) => {
  return (
    <a href={link} className='d-flex align-items-center bg-hover-secondary-o-2 rounded-sm p-1 mb-4'>

      <div className='symbol symbol-40 symbol-light-primary mr-5'>
        <span className='symbol-label'>
          <span className='svg-icon svg-icon-lg svg-icon-primary ml-1'>
            {isNew ? <IconNew /> : <IconOk />}
          </span>
        </span>
      </div>

      <div className='d-flex flex-column font-weight-bold'>
        <span className='text-dark mb-1 font-size-lg'>{title}</span>
        <span className='text-muted'>{summary}</span>
      </div>
    </a>
  )
}
