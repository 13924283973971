import React, { useEffect, useState } from 'react'
import { NotificationItem } from '../notification-item'
import Api from '../../../../util/Api'
import './styles.scss'

export const Notification = () => {
  const [notificaciones, setNotificaciones] = useState([])
  const [number, setNumber] = useState(0)

  useEffect(async () => {
    let response = await Api.get('/notificaciones')
    if (!response.length) {
      response = []
    }
    const notRead = response.filter(notif => notif.viewed === 0)
    setNumber(notRead.length)
    setNotificaciones(response)
  }, [])

  const readNotifications = async () => {
    await Api.post('/notificaciones/read-all')
    setNumber(0)
  }

  return (
    <div className='dropdown'>
      <div className='topbar-item' onClick={readNotifications} data-toggle='dropdown' aria-haspopup='true' aria-expanded='false' data-offset='10px,0px'>
        <div className='btn btn-bg-white btn-icon-success btn-hover-primary btn-icon mr-3 my-2 my-lg-0 pulse'>
          <i className='flaticon2-bell-1 icon-xl icon-gray' />
          <span className='pulse-ring' />
          {
          number > 0 &&
            <i className='alerts-badge bg-primary'> {number} </i>
          }

        </div>
      </div>
      <div className='dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg'>
        <form>
          <div className='tab-content'>
            <div className='tab-pane active show p-8' id='topbar_notifications_notifications' role='tabpanel'>
              <div className='pr-7 mr-n7 notification__content'>
                {
                  notificaciones.length > 0 && notificaciones.map((notificacion, key) => {
                    return (
                      <NotificationItem
                        isNew={!notificacion.viewed}
                        key={key}
                        title={notificacion.title}
                        summary={notificacion.summary}
                        link={notificacion.url}
                      />
                    )
                  })
                }

                {
                  (notificaciones.length === 0 || !notificaciones) &&
                    <div className='d-flex align-items-center bg-hover-secondary-o-2 rounded-sm p-1 mb-4'>
                      <p className='ml-auto mr-auto'>No tienes notificaciones</p>
                    </div>
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
