import React, { useState, useEffect, useContext } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
// import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import esLocale from '@fullcalendar/core/locales/es'
import { CalendarEventItem } from '../../components/calendar-event-item'
import { ModalTratamiento } from '../../components/modal-tratamiento'
import './styles.scss'
import { transformCalendar } from './transforms'
import { Context } from '../../Context'
import { pacienteService } from '../../services/paciente.service'

export const Calendario = () => {
  const { nextApplying, producto } = useContext(Context)
  const [state, setstate] = useState({
    showModal: false,
    events: []
  })

  const toggleModal = (e) => {
    if (nextApplying && !e.event.extendedProps.isAlreadyTake) {
      setstate({
        ...state,
        showModal: true
      })
    }
  }
  const onClose = () => {
    setstate({
      ...state,
      showModal: false
    })
  }

  useEffect(() => {
    (async () => {
      try {
        const response = await pacienteService.getPrograma()
        const dataCalendar = transformCalendar(response, producto.id)
        setstate({ ...state, events: dataCalendar })
      } catch (_) { }
    })()
  }, [nextApplying])

  const getTextByProductPlural = () => {
    if (producto && (producto.id === 18)) {
      return 'Tomas'
    }

    return 'Aplicaciones'
  }

  return (
    <>
      <div className='d-flex flex-column-fluid py-15'>
        <div className='container'>
          <div className='card card-custom'>
            <div className='card-header'>
              <div className='card-title'>
                <h4 className='text-uppercase font-weight-boldest w-100 mt-1 mb-0'>{getTextByProductPlural()} de medicamento</h4>
              </div>
            </div>
            <div className='card-body calendario'>
              <FullCalendar
                locale={esLocale}
                plugins={[dayGridPlugin, bootstrapPlugin]}
                initialView='dayGridMonth'
                themeSystem='bootstrap'
                height={800}
                contentHeight={780}
                aspectRatio={3}
                events={state}
                editable
                navLinks
                dayHeaderClassNames='calendario__header'
                dayCellClassNames='calendario__dayCell'
                eventContent={CalendarEventItem}
                eventClick={toggleModal}
              />
              <p className='text-black-50 font-size-base font-italic mt-7 mb-0'>Las tomas son consideradas de acuerdo al prospecto del  medicamento o la indicación médica</p>
            </div>
          </div>
        </div>
      </div>
      {state.showModal ? <ModalTratamiento onClose={onClose} onRegister={onClose} /> : null}
    </>
  )
}
