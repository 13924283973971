import React from 'react'
import { Switch } from 'react-router-dom'
import { RouteWrapper } from './routeWrapper'
import { Layout } from '../pages/layout'
import { Calendario } from '../pages/calendario'
import Home from '../pages/Home/Home'
import HomeServicios from '../pages/Servicios/HomeServicios'
import Aplicacion from '../pages/Servicios/Aplicacion'
import Enfermeria from '../pages/Servicios/Enfermeria'
import Infusion from '../pages/Servicios/Infusion'
import Territorial from '../pages/Servicios/Territorial'
import Apoyo from '../pages/Servicios/Apoyo'
import { NotFound } from '../pages/notFound'
import { MaterialesEducativos } from '../pages/materiales-educativos'
import Perfil from '../pages/Perfil/Perfil'
import { SearchResult } from '../pages/search-result'
import { Encuestas } from '../pages/encuestas'
import { Encuesta } from '../pages/encuesta'
import { AccesoMedicacion } from '../pages/acceso-medicacion'

export const Routes = () => {
  return (
    <Switch>
      <RouteWrapper exact path='/' component={Home} layout={Layout} />
      <RouteWrapper path='/calendario' component={Calendario} layout={Layout} />
      <RouteWrapper path='/materiales-educativos' component={MaterialesEducativos} layout={Layout} />
      <RouteWrapper path='/resultados' component={SearchResult} layout={Layout} />
      <RouteWrapper path='/servicios/aplicacion-en-centros' component={Aplicacion} layout={Layout} />
      <RouteWrapper path='/servicios/aplicacion-en-domicilio' component={Enfermeria} layout={Layout} />
      <RouteWrapper path='/servicios/centro-de-infusion' component={Infusion} layout={Layout} />
      <RouteWrapper path='/servicios/asesoramiento-territorial' component={Territorial} layout={Layout} />
      <RouteWrapper path='/servicios/apoyo-y-seguimiento' component={Apoyo} layout={Layout} />
      <RouteWrapper path='/servicios' component={HomeServicios} layout={Layout} />
      <RouteWrapper path='/perfil' component={Perfil} layout={Layout} />
      <RouteWrapper path='/encuesta/:idEncuesta' component={Encuesta} layout={Layout} />
      <RouteWrapper path='/encuesta' component={Encuestas} layout={Layout} />
      <RouteWrapper path='/acceso-a-medicacion' component={AccesoMedicacion} layout={Layout} />
      <RouteWrapper path='*' component={NotFound} layout={Layout} />
    </Switch>
  )
}
