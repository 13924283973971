import React from 'react'
import './styles.scss'
// import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const CalendarEventItem = (props) => {
  let color = 'danger'

  switch (props.event.extendedProps.isAlreadyTake) {
    case 'si':
      color = 'success'
      break
    case 'no':
      color = 'error'
      break
    case 'next':
      color = 'warning'
      break
    default:
      break
  }

  return (
  // <OverlayTrigger
  //   placement='top'
  //   overlay={
  //     <Tooltip id='tooltip'>
  //       {props.event.extendedProps.tooltip}
  //     </Tooltip>
  //   }
  // >
    <div className='canlendar-event-item'>
      <div className={`canlendar-event-item__circle canlendar-event-item__circle--${color}`} />
      <p className='canlendar-event-item__text'>{props.event.extendedProps.description}</p>
    </div>
  // </OverlayTrigger>
  )
}
