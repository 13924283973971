import React, { useState } from 'react'
import Loading from '../Loading'
import Api from '../../util/Api'
import './styles.scss'

const Alerts = ({ esquemaActual = null, notificacionesMedicacion, notificacionesExtras }) => {
  const MIN = 1
  const MAX = 60
  const [manualFrequency, setManualFrequency] = useState(esquemaActual !== 30)
  const [frequency, setFrequency] = useState(esquemaActual)
  const [frequencyError, setFrequencyError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notificacionMedicacion, setNotificacionMedicacion] = useState(notificacionesMedicacion)
  const [notificacionExtras, setNotificacionExtras] = useState(notificacionesExtras)

  const handleManualFrequency = async () => {
    const newState = !manualFrequency
    setManualFrequency(newState)
    await Api.post('/pacientes/me/esquema-terapeutico', { frecuencia: -1 })
  }

  const handleFrequency = (e) => {
    const newFrequency = Number(e.target.value)

    if (newFrequency >= MIN && newFrequency <= MAX) {
      setFrequencyError(false)
    } else {
      setFrequencyError(true)
    }
    setFrequency(e.target.value)
  }

  const handleSave = async () => {
    setLoading(true)
    await Api.post('/pacientes/me/esquema-terapeutico', { frecuencia: frequency })
    setLoading(false)
  }

  const handleNotifications = async (e) => {
    const payload = {}

    if (e.target.name === 'medicacion') {
      const value = notificacionMedicacion === 1 ? 0 : 1
      setNotificacionMedicacion(value)
      payload.notificacionesMedicacion = value
    }

    if (e.target.name === 'extras') {
      const value2 = notificacionExtras === 1 ? 0 : 1
      setNotificacionExtras(value2)
      payload.notificacionesExtras = value2
    }
    await Api.put('/usuarios', payload)
  }

  return (
    <>
      <div class='card card-custom mt-9'>
        <div class='card-header py-3'>
          <div class='card-title align-items-start flex-column'>
            <h3 class='card-label font-weight-bolder text-dark'>Alertas</h3>
            <span class='text-muted font-weight-bold font-size-sm mt-1'>Configura qué alertas quieres que te enviemos</span>
          </div>
        </div>
        <div class='card-body'>
          <div class='form-group fv-plugins-icon-container mb-0'>
            <label class='checkbox mb-0'>
              <div class='ml-2'>Deseo recibir alertas para las tomas de medicación.</div>
              <input onClick={handleNotifications} checked={notificacionMedicacion} type='checkbox' name='medicacion' />
              <span style={{ borderColor: '#7d7c7c' }} />
            </label>
          </div>
          <hr style={{ borderColor: '#ecf0f3' }} />
          <div class='form-group fv-plugins-icon-container mb-0'>
            <label class='checkbox mb-0'>
              <div class='ml-2'> Deseo recibir Notificaciones de Materiales y encuestas.</div>
              <input onClick={handleNotifications} checked={notificacionExtras} type='checkbox' name='extras' />
              <span style={{ borderColor: '#7d7c7c' }} />
            </label>
          </div>

        </div>
      </div>

      <div class='card card-custom mt-9'>
        <div class='card-header py-3'>
          <div class='card-title align-items-start flex-column'>
            <h3 class='card-label font-weight-bolder text-dark'>Frecuencia manual</h3>
            <span class='text-muted font-weight-bold font-size-sm mt-1'>Configura la frecuencia de tus tomas</span>
          </div>
        </div>
        <div class='card-body'>
          <div class='form-group fv-plugins-icon-container mb-0'>
            <label class='checkbox mb-0'>
              <div class='ml-2'>Activar frecuenca de toma manual.</div>
              <input type='checkbox' checked={manualFrequency} onChange={handleManualFrequency} name='condiciones' />
              <span style={{ borderColor: '#7d7c7c' }} />
            </label>
          </div>
          {
              manualFrequency &&
                <>
                  <div class='form-group fv-plugins-icon-container mt-5'>
                    <div class='ml-2'>Frecuencia (días):</div>
                    <input type='number' value={frequency} onChange={handleFrequency} placeholder='Escribe el número de días' name='frequency' class='form-control form-control-lg form-control-solid' style={{ borderColor: frequencyError && '#dc3545' }} />
                    <div class='invalid-feedback' style={{ display: frequencyError ? 'inline' : 'none' }}>
                      Elige valor entre {MIN} y {MAX}
                    </div>
                  </div>
                  <button onClick={handleSave} disabled={frequencyError} class='btn btn-primary font-size-h9 float-right'>
                    {
                        loading ? <Loading /> : 'Guardar'
                    }
                  </button>
                </>
          }

        </div>
      </div>
    </>
  )
}

export default Alerts
