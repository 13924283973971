/* eslint-disable no-unused-vars */
import React, { createContext, useReducer, useEffect } from 'react'
import Cookies from 'js-cookie'
import { pacienteService } from './services/paciente.service'
import * as moment from 'moment'
import { envConfig } from './config'

export const Context = createContext()

const initialState = {
  loading: true,
  token: Cookies.get('auth_token') || null,
  nextApplying: null,
  producto: null
}

const validateNextApplying = (fecha) => {
  const timeStampNextApplying = moment(fecha).toDate().getTime()
  return Date.now() >= timeStampNextApplying ? timeStampNextApplying : null
}

const reduce = (state, action = {}) => {
  switch (action.type) {
    case 'take-medication':
      return { ...state, nextApplying: action.value, loading: false }
    case 'preload-context':
      return { ...state, nextApplying: action.value.nextApplying, loading: false, producto: action.value.producto }
    default:
      return state
  }
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reduce, initialState)
  useEffect(() => {
    const preloadContext = async () => {
      try {
        const response = await pacienteService.getPrograma()
        console.log('response context', response)
        let nextApplying = response.proximaAplicacion ? validateNextApplying(response.proximaAplicacion.fecha) : null

        if (response?.calendarioAplicaciones.length > 0) {
          // Este if es para el siguiente caso:
          // Si pongo que NO tome hoy y recargo que no me vuelva a aparecer el boton de confirmar.
          // al dia sigueinte puede aparecer de nuevo
          const last = response.calendarioAplicaciones[response.calendarioAplicaciones.length - 1]
          const current = moment()
          if (last.realizada === false && moment(last.fecha).isSame(current, 'day')) {
            nextApplying = null
          }
        }

        dispatch({ type: 'preload-context', value: { nextApplying, producto: response.producto } })
      } catch (error) {
        // cuando falla el api ver q se hace
      }
    }
    preloadContext()
  }, [])

  // aca podemos meter el redirect cuando el token no esta
  if (!state.token) {
    window.location.href = envConfig.baseUrlPublic + '/ingresar'
  }

  const context = {
    ...state,
    async updateTakeMedication (value) {
      try {
        const response = await pacienteService.setMedication(value)
        console.log('setMedication', response)
        dispatch({ type: 'take-medication', value: null })
        window.location.href = '/'
      } catch (error) {
        dispatch({ type: 'take-medication', value: null })
      }
    }
  }
  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default {
  Provider,
  Consumer: Context.Consumer
}
