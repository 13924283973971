import React from 'react'
export const Hero = () => (
  <div className='card card-custom mb-12'>
    <div className='card-body rounded p-0 d-flex header-materiales MaterialesEducativos__header'>
      <div className='d-flex flex-column flex-lg-row-auto w-auto w-lg-350px w-xl-450px w-xxl-500px p-10 p-md-20'>
        <h1 className='font-weight-bolder'>Materiales educativos</h1>
        <div className='font-size-h4 mb-8'>Busque y descargue el material que necesite para obtener más información sobre los tratamientos.</div>
      </div>
      <div className='d-none d-md-flex flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover MaterialesEducativos__hero' />
    </div>
  </div>
)
