import React, { useState, useEffect } from 'react'
import { Row } from './components/row'
import { useLocation } from 'react-router-dom'
import { Pagination } from '../../components/pagination'
import './styles.scss'
import { search } from './service'

const useQuery = () => new URLSearchParams(useLocation().search)

export const SearchResult = () => {
  const query = useQuery()
  const [state, setState] = useState({
    listResult: [],
    row: 0,
    partialResult: [],
    page: 0
  })
  useEffect(() => {
    const resultSeach = search(query.get('q'))
    setState({
      listResult: resultSeach,
      row: Math.ceil(resultSeach.length / 10),
      partialResult: resultSeach.slice(0, 10),
      page: 0
    })
  }, [query.get('q')])

  const handlePageClick = ({ selected }) => {
    setState({
      ...state,
      partialResult: state.listResult.slice((selected * 10), 10 + (selected * 10)),
      page: selected
    })
  }

  return (
    <div className='d-flex flex-column-fluid py-15' style={{ backgroundColor: '#eef0f8' }}>
      <div className='container'>
        <div className='d-flex flex-row'>
          <div className='flex-row-fluid'>
            <div className='card card-custom card-stretch gutter-b'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-12'>
                    <h5 className='font-weight-boldest w-100 mb-5'>Resultados de: {query.get('q')}</h5>
                    <div className='tab-content mt-5'>
                      <div className='tab-pane fade show active'>
                        <div className='table-responsive'>
                          <table className='table table-vertical-center mb-0'>
                            <tbody>
                              {state.partialResult.map((item, index) => <Row key={index} title={item.title} description={item.description} href={item.href} />)}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='search-result__pagination'>
                <Pagination onPageChange={handlePageClick} pageCount={state.row} forcePage={state.page} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
