export const listMatch = [
  {
    title: 'Canlendario',
    description: '',
    tags: ['toma de medicamentos', 'medicamentos', 'medicamento', 'calendario'],
    href: '/calendario'
  },
  {
    title: 'Servicios de centros de aplicación',
    description: '',
    tags: ['Para poder solicitar Servicio de Centros de Aplicacion, adjunte la orden medica / receta en el siguiente formulario y haga click en ENVIAR para poder recibir información al respecto y ser contactado por un asesor',
      'El servicio de centros de aplicación estará disponible de acuerdo al tipo de tratamiento de cada paciente, su patología y su localización geográfica.',
      'Servicios de centros de aplicación',
      'Servicios'],
    href: '/servicios/aplicacion-en-centros'
  },
  {
    title: 'Servicio de enfermería',
    description: '',
    tags: ['Para poder solicitar Servicio de Enfermeria, adjunte la orden medica / receta en el siguiente formulario y haga click en ENVIAR para poder recibir información al respecto y ser contactado por un asesor',
      'El servicio de enfermería estará disponible de acuerdo al tipo de tratamiento de cada paciente, su patología y su localización geográfica.',
      'Servicio de enfermería',
      'Servicios'],
    href: '/servicios/aplicacion-en-domicilio'
  },
  {
    title: 'Servicio de Centros de infusión',
    description: '',
    tags: ['Para poder solicitar Servicio de centro de infusion, adjunte la orden medica / receta en el siguiente formulario y haga click en ENVIAR para poder recibir información al respecto y ser contactado por un asesor',
      'El servicio de infusión estará disponible de acuerdo al tipo de tratamiento de cada paciente, su patología y su localización geográfica.',
      'Servicio de Centros de infusión',
      'Servicios'],
    href: '/servicios/centro-de-infusion'
  },
  {
    title: 'Servicio de asesoramiento en acceso',
    description: '',
    tags: ['Para poder solicitar Servicio de asesoramiento territorial, haga click en ENVIAR en el siguiente formulario y sera contactado por un asesor',
      'Servicio de asesoramiento en acceso',
      'Servicios'],
    href: '/servicios/asesoramiento-territorial'
  },
  {
    title: 'Servicio de asesoramiento en acceso',
    description: '',
    tags: ['Para poder solicitar apoyo y seguimiento, haga click en Solicitar en el siguiente formulario y será contactado por un asesor',
      'Apoyo y seguimiento',
      'Servicios'],
    href: '/servicios/apoyo-y-seguimiento'
  },
  {
    title: 'Materiales educativos',
    description: '',
    tags: ['Busque y descargue el material que necesite para obtener más información sobre los tratamientos.',
      'education'],
    href: '/materiales-educativos'
  }
]
