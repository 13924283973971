import React from 'react'

const ImageCard = ({ name = undefined, phone = undefined, email = undefined }) => {
  return (
    <>
      <div class='card card-custom '>
        <div class='card-body'>
          <div class='d-flex align-items-top'>
            <span class='font-weight-bolder font-size-h5 text-dark-75'> {name} </span>
            <div class='text-muted' />
          </div>
          <div class='pt-9'>
            {
            email &&
              <div class='d-flex align-items-center justify-content-between mb-2'>
                <span class='font-weight-bold mr-2'>Email:</span>
                <span class='text-muted'> {email} </span>
              </div>
            }
            {
              phone &&
                <div class='d-flex align-items-center justify-content-between mb-2'>
                  <span class='font-weight-bold mr-2'>Teléfono:</span>
                  <span class='text-muted'> {phone} </span>
                </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ImageCard
