import React from 'react'

function Doctor ({ doctor = undefined }) {
  return (
    <div class='d-flex flex-wrap'>
      <h4 class='font-weight-boldest w-100 mb-10'>INFORMACIÓN DE MI MÉDICO</h4>
      <div class='doctor-image d-flex justify-content-center align-items-center w-80px h-80px display1 text-muted mr-7'>
        <DoctorIcon />
      </div>
      <div class='d-flex align-items-center'>
        <div class='d-flex align-items-center justify-content-between flex-wrap'>
          <div class='mr-3'>
            <span class='d-flex align-items-center text-dark text-hover-primary font-size-h3 mr-3'>
              {
                  doctor
                    ? `${doctor.nombre || ''} ${doctor.apellido || ''}`
                    : 'Sin médico asignado'
                }
            </span>
            <div class='d-flex flex-wrap'>
              <span class='text-muted text-hover-primary font-size-h5 mr-lg-8 mr-5 mb-lg-0 mb-2'>
                {
                  doctor && doctor.especialidades.length > 0
                    ? doctor.especialidades[0]
                    : '-'
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DoctorIcon = () => {
  return (
    <span class='svg-icon svg-icon-primary svg-icon-6x d-flex align-items-center justify-content-center'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57.18 80.05'>
        <g id='Layer_2' data-name='Layer 2'>
          <g id='Layer_1-2' data-name='Layer 1'>
            <path style={{ fill: '#F36633' }} d='M55.9,57.38a9.48,9.48,0,0,0-8.19-4.22h-.07A18,18,0,0,0,40,55.24c-1.64.76-3.24,1.63-4.81,2.51,0-.8,0-1.6.08-2.41v-.07l0-6.4c5.35-.8,9.14-3.86,9.32-7.79L50,12.52c.22-1.13.65-4-.18-5.86a9,9,0,0,0-5.47-4.88c-.18-.07-.43-.14-.72-.22h0A3.87,3.87,0,0,0,40.57,0a3.84,3.84,0,0,0,0,7.68,3.8,3.8,0,0,0,2-.58h0c.11,0,.22,0,.33.07a3.59,3.59,0,0,1,1.75,1.71,8.56,8.56,0,0,1-.15,2.55L39,40.24l0,.26v.29c0,.91-2.58,2.69-6.63,2.69s-6.62-1.78-6.62-2.69V40.5L20.07,11.43a9.56,9.56,0,0,1-.18-2.55,3.6,3.6,0,0,1,1.78-1.71A1.4,1.4,0,0,1,22,7.1h0A3.84,3.84,0,1,0,24,0,4,4,0,0,0,20.9,1.56h0l-.72.22a9.06,9.06,0,0,0-5.43,4.88c-.84,1.89-.4,4.73-.18,5.82l5.5,28.6c.18,4,4.08,7.06,9.5,7.82l0,6.23A40,40,0,0,0,29.72,61a3,3,0,0,0-.37.22c-4.84,3-9.43,5.78-14.49,7.46-1.38.44-3.1.91-4.37.47a2,2,0,0,1-1.06-.91,1,1,0,0,1-.11-.36,6.22,6.22,0,1,0-9-3.39,6.32,6.32,0,0,0,3.42,3.72h0a6.19,6.19,0,0,0,.66,2.47,7.46,7.46,0,0,0,4.26,3.75,12.24,12.24,0,0,0,7.9-.44,64.38,64.38,0,0,0,14.09-7,48.91,48.91,0,0,0,1.83,5.89,11.58,11.58,0,0,0,4.18,5.83,8.57,8.57,0,0,0,4.74,1.34,12.62,12.62,0,0,0,6.15-1.75,19.21,19.21,0,0,0,9.51-14,10.57,10.57,0,0,0-1.17-6.92ZM51.5,63.6a13.54,13.54,0,0,1-6.71,9.87c-1.49.83-3.71,1.49-5.06.58a6.48,6.48,0,0,1-2-3.17,38.2,38.2,0,0,1-2-6.95c2.15-1.27,4.37-2.51,6.59-3.56a13.09,13.09,0,0,1,5.35-1.57,4,4,0,0,1,3.42,1.53,5.21,5.21,0,0,1,.41,3.27Z' />
          </g>
        </g>
      </svg>
    </span>
  )
}

export default Doctor
