import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/media/logos/GSK_L_RGB.png'
import logoMas from '../../assets/media/logos/LOGO_MAS.png'
import { CusmtomLink } from './link'
import { LinkWithListLink } from './linkWithListLink'
import { User } from './user'

export const Header = () => {
  return (
    <div id='kt_header' className='header header-fixed subnavbar-on'>
      <div className='container-fluid d-flex align-items-stretch justify-content-between'>
        <div className='header-menu-wrapper header-menu-wrapper-left flex-grow-1 justify-content-between' id='kt_header_menu_wrapper'>
          <div className='header-logo'>
            <Link to='/' className='d-flex align-items-center'>
              <img alt='Logo' src={logo} className='gsk mr-5' />
              <img alt='Programa Mas' src={logoMas} className='programa-mas mr-5' />
            </Link>
          </div>
          <div id='kt_header_menu' className='header-menu header-menu-mobile header-menu-layout-tab header-menu-root-arrow mr-4'>
            <ul className='menu-nav'>
              <CusmtomLink activeOnlyWhenExact to='/' label='Inicio' />
              <CusmtomLink activeOnlyWhenExact to='/calendario' label='Calendario' />
              <LinkWithListLink />
              <CusmtomLink activeOnlyWhenExact to='/materiales-educativos' label='Materiales educativos' />
              <CusmtomLink activeOnlyWhenExact to='/acceso-a-medicacion' label='Acceso a la medicación' />
              <CusmtomLink activeOnlyWhenExact to='/encuesta' label='Encuesta' />
            </ul>
          </div>
        </div>
        <User />
      </div>
    </div>
  )
}
