import React, { useState, useEffect } from 'react'
import Api from '../../util/Api'
import Loading from '../../components/Loading'
import MultiFile from '../../components/servicios/MultiFile'
import { Modal } from 'react-bootstrap'
import { envConfig } from '../../config'

const CentrosInfusion = () => {
  const [sending, setSending] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModalConfirmation, setShowModalConfirmation] = useState(false)
  const [buttonBlocked, setButtonBlocked] = useState(false)
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(async function () {
    setLoading(true)
    const userData = await Api.get('/pacientes/me')
    setUser(userData || {})
    setLoading(false)
  }, [])

  const handleForm = async () => {
    setSending(true)
    setButtonBlocked(true)
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'multipart/form-data; boundary=--------------------------173375425196423528230954')

    const formdata = new FormData()
    const payload = {
      Token: user.pharmexToken,
      IdPacientePrograma: user.idPacientePrograma,
      TipoActividad: 'ServicioCentroInfusion'
    }

    formdata.append('SolicitarActividadRequest', JSON.stringify(payload))

    let i = 1
    for (const order of orders) {
      const key = i === 1 ? '' : i
      const nameSplit = order.name.split('.')
      formdata.append('Archivos' + key, order, 'file' + i + '.' + nameSplit[nameSplit.length - 1])
      i++
    }

    const requestOptions = {
      method: 'POST',
      headers: undefined,
      body: formdata,
      redirect: 'follow'
    }

    fetch(envConfig.apiPharmexUrl + '/pacienteprograma/actividad', requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        setShowModal(true)
        setShowModalConfirmation(false)
        setSending(false)
      })
  }

  const toggleModalConfirmation = e => {
    setShowModalConfirmation(!showModalConfirmation)
  }

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const onMultiFileChange = files => {
    setOrders(files)
    console.log('files after callback', files)
  }

  if (loading) {
    return (
      <div class='d-flex flex-column-fluid py-15 h-1000px' style={{ backgroundColor: '#eef0f8' }}>
        <div class='container'>
          <div class='d-flex flex-row'>
            <Loading size='30' className='ml-auto mr-auto' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div class='d-flex flex-column-fluid py-15' style={{ backgroundColor: '#eef0f8' }}>

      <div class='container'>

        <div class='card card-custom' id='aplicacionCentros'>
          <div class='card-header'>
            <h3 class='card-title'>Consultar Servicio de Centros de infusión </h3>
          </div>
          <form class='form' name='kt_centros_aplicacion'>
            <div class='card-body'>
              <p class='mb-8'>Para poder solicitar Servicio de centro de infusión, adjunte la orden Médica / receta en el siguiente formulario y haga click en ENVIAR para poder recibir información al respecto y ser contactado por un asesor</p>
              <div class='form-group form-group-last'>
                <div class='alert alert-custom alert-default' role='alert'>
                  <div class='alert-icon'><i class='flaticon-warning text-primary' /></div>
                  <div class='alert-text'>
                    El servicio de infusión estará disponible de acuerdo al tipo de tratamiento de cada paciente, su patología y su localización geográfica.
                  </div>
                </div>
              </div>
              <div class='form-group'>
                <label>Nombre</label>
                <input disabled name='nombre' id='nombre' type='text' class='form-control form-control-lg' placeholder='Nombre' value={`${user && user.nombre}`} required />
              </div>
              <div class='form-group'>
                <label>Apellido</label>
                <input disabled name='apellido' id='apellido' type='text' class='form-control form-control-lg' placeholder='Apellido' value={`${user && user.apellido}`} required />
              </div>
              <div class='form-group'>
                <label>DNI</label>
                <input disabled name='dni' id='dni' type='text' class='form-control form-control-lg' placeholder='DNI' value={`${user && user.nroDocumento}`} required />
              </div>

              <MultiFile maxInputs='5' title='Adjunte Orden Médica/Receta (Máximo 5)' onChange={onMultiFileChange} />

            </div>
            <div class='card-footer'>
              <button type='button' onClick={toggleModalConfirmation} class='btn btn-primary mr-2' disabled={buttonBlocked}>Enviar</button>
              <p class='mt-5'>
                Disponibilidad de centros especializados para la infusión de la medicación Belimumab IV.
                Servicio sujeto a disponibilidad geográfica.

              </p>
            </div>

            <Modal show={showModalConfirmation} onHide={toggleModalConfirmation} size='lg'>
              <Modal.Header>
                <h3 className='font-weight-boldest font-size-h2 text-uppercase text-center w-100 mb-0' id='eventModalLabel'>Centros de infusión</h3>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className='px-13 text-center'>
                <p>  Al confirmar, enviará una solicitud a un asesor. </p>
                <p> ¿Desea confirmar? </p>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' className='btn btn-primary font-size-h6' data-dismiss='modal' onClick={handleForm}>
                  {sending ? <Loading className='ml-2 mr-2' /> : 'Confirmar'}
                </button>
                <button type='button' className='btn btn-secondary font-size-h6' data-dismiss='modal' onClick={toggleModalConfirmation}>Cancelar</button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={toggleModal} size='lg'>
              <Modal.Header>
                <h3 className='font-weight-boldest font-size-h2 text-uppercase text-center w-100 mb-0' id='eventModalLabel'>Solicitud para centros de infusión</h3>
                <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                  <span aria-hidden='true'>&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body className='px-13 text-center'>
                <p><strong>Su solicitud servicio de infusión se envió con éxito.</strong></p>
                <p> A la brevedad nos comunicaremos con usted para confirmar la solicitud.</p>
              </Modal.Body>
              <Modal.Footer>
                <button type='button' className='btn btn-primary font-size-h6' data-dismiss='modal' onClick={toggleModal}>Volver</button>
              </Modal.Footer>
            </Modal>

          </form>
        </div>

      </div>
    </div>
  )
}

export default CentrosInfusion
