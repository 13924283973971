import React, { useEffect, useState } from 'react'
import Api from '../../../util/Api'
import Loading from '../../Loading'
import { Modal } from 'react-bootstrap'

function Consentimiento () {
  const [mostrar, setMostrar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [aceptado, setAceptado] = useState(false)

  useEffect(async function () {
    const response = await Api.get('/pacientes/me')

    if (response.mostrarConsentimiento) {
      setMostrar(true)
    }

    console.log('Consent', response)
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    await Api.post('/read-consentimiento')
    setMostrar(false)
    setLoading(false)
  }

  const handleAceptado = () => {
    setAceptado(!aceptado)
    console.log('cam')
  }

  if (mostrar) {
    return (
      <div className='row mb-5'>
        <Modal show={mostrar} size='xl'>
          <Modal.Header>
            <h3 className='font-weight-boldest font-size-h2 text-uppercase text-center w-100 mb-0' id='eventModalLabel'>
              Consentimiento Informado
            </h3>
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body className='px-13'>
            <h4 className='text-center'>CONSENTIMIENTO PARA PARTICIPACIÓN EN EL PROGRAMA MEJOR ACCESO A LA SALUD  Y PARA EL PROCESAMIENTO DE DATOS EN EL MARCO DEL PROGRAMA</h4>
            <br />
            <p>
              Declaro  que  he  comprendido  la  información  que  se  me  ha  dado  en  relación  con  el  programa  de  Mejor Acceso a la Salud (el “Programa”) y por medio del presente declaro mi voluntad de ingresar, participar y ser parte del Programa, o en su caso de que el menor de edad a quien represento sea ingresado, participe y sea parte del Programa.
            </p>
            <p>
              Por la presente doy mi consentimiento previo, expreso e informado en los términos de la Ley de Protección de  Datos  Personales  N°  25.326  (artículos  5  y  6),  a  GlaxoSmithKline  Argentina  S.A.,  con  domicilio  en Carlos Casares 3690, Victoria, Provincia de Buenos Aires (“GSK”), como patrocinador del Programa, para que recolecten y procesen mis datos personales o los datos personales del menor de edad a quien represento que hayan sido suministrados por mí a GSK, incluyendo datos médicos y de salud (los “Datos Personales”), únicamente para poder ingresar, participar y ser contactado en el marco del Programa.
            </p>

            <p>
              Entiendo y acepto que la entrega de los Datos Personales tanto como la participación en el Programa es voluntaria.  Sin  embargo,  entiendo  que,  si  no  consiento  el  procesamiento  de  los  Datos  Personales  o  si proporciono datos falsos o inexactos, ello podría impedir o dificultar la participación en el Programa.
            </p>
            <p>
              Asimismo,  fui  informado  de  que  GSK  utilizará  los  Datos  Personales  exclusivamente  para  cumplir  con cualquier  obligación  legal  a  su  cargo,  para  compartir  información  médica  de  su  interés,  para  asegurar  la correcta actualización, resguardo y procesamiento de los Datos Personales según la finalidad expresada en el presente y/o para controlar los servicios de quien procese los Datos Personales por cuenta y orden de GSK. Declaro conocer que podré ejercer el derecho a acceder, rectificar, actualizar y suprimir los Datos Personales mediante el envío de un correo electrónico a la dirección ar.programamas@gsk.com y he sido informado que  la  Agencia  de  Acceso  a  la  Información  Pública,  en  su  carácter  de  Órgano  de  Control  de  la  Ley  N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.
            </p>
            <p>
              Declaro conocer y aceptar que los Datos Personales que suministre a GSK, de modo directo o a través del médico tratante o del tercero que GSK eventualmente pueda designar, serán recolectados y tratados como confidenciales. Sin perjuicio de lo expuesto, GSK podrá divulgar y transferir total o parcialmente los Datos Personales a: (i) las autoridades de salud y demás agencias gubernamentales cuando ello sea necesario (por ejemplo, ANMAT); (ii) proveedores de servicios de GSK; (iii) afiliadas de GSK y/u otras empresas en caso de fusión y/o adquisición y/o escisión o cesión de negocio de GSK; y/o (iv) cualquier otra empresa o persona física cuando sea permitido o exigido por la ley. Declaro conocer y acepto que dichas transferencias de los Datos Personales podrán incluso realizarse a países que no provean el mismo nivel de protección que la Argentina, y que en estos casos, se tomarán las medidas de protección de datos personales pertinentes con el objeto de asegurar la confidencialidad y seguridad de los Datos Personales de acuerdo con los estándares de seguridad vigentes en Argentina.
            </p>
            <p>
              Para más información sobre el tratamiento de los Datos Personales por favor consulta nuestro <a href='http://gsk.elpixel.net/condiciones-de-uso'>Aviso de Privacidad</a>
            </p>
            <br />
            <div class='form-check' style={{ fontSize: 15 }}>
              <input class='form-check-input' style={{ border: '1px solid #00000085' }} type='checkbox' checked={aceptado} onChange={handleAceptado} id='flexCheckChecked' />
              <label class='form-check-label' for='flexCheckChecked' />
              <b>Acepto expresamente la recolección y el procesamiento de los Datos Personales por parte de GSK.</b>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' disabled={!aceptado} className='btn btn-primary font-size-h6' data-dismiss='modal' onClick={handleSubmit}>
              {
              loading ? <Loading /> : 'Aceptar'
            }
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  return (
    <div />
  )
}

export default Consentimiento
