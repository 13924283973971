import React, { useEffect, useState } from 'react'
import Api from '../../util/Api'

import Loading from '../../components/Loading'
import ImageCard from '../../components/perfil/ImageCard'
import Alerts from '../../components/perfil/Alerts'
import ChangePassword from '../../components/perfil/ChangePassword'

const Perfil = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})
  const [producto, setProducto] = useState({})
  const [programa, setPrograma] = useState({})

  useEffect(async function () {
    setLoading(true)
    const userData = await Api.get('/pacientes/me')
    const programaData = await Api.get('/pacientes/me/programas')
    setPrograma(programaData)
    setUser(userData || {})
    setProducto(userData.producto || {})
    setLoading(false)
  }, [])

  if (loading) {
    return (
      <div class='d-flex flex-column-fluid py-15 h-1000px' style={{ backgroundColor: '#eef0f8' }}>
        <div class='container'>
          <div class='d-flex flex-row'>
            <Loading size='30' className='ml-auto mr-auto' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div class='d-flex flex-column-fluid py-15' style={{ backgroundColor: '#eef0f8' }}>
      <div class='container'>
        <div class='d-flex flex-row'>
          <div class='flex-row-auto offcanvas-mobile w-250px w-xxl-350px' id='kt_profile_aside'>
            <ImageCard email={user.email} name={`${user.nombre} ${user.apellido}`} phone={user.telefono} />
            <Alerts
              notificacionesExtras={user.notificacionesExtras}
              notificacionesMedicacion={user.notificacionesMedicacion}
              esquemaActual={programa.productoEsquemaDias}
            />
          </div>
          <div class='flex-row-fluid ml-lg-8'>
            <form novalidate='novalidate' id='f_signup' method='POST' name='kt_login_signup_form'>
              <div class='card card-custom'>
                <div class='card-header py-3'>
                  <div class='card-title align-items-start flex-column'>
                    <h3 class='card-label font-weight-bolder text-dark'>Perfil de usuario</h3>
                    <span class='text-muted font-weight-bold font-size-sm mt-1'>Edite sus datos de usuario</span>
                  </div>
                  {/* <div class='card-toolbar'>
                    <button  class='btn btn-primary font-size-h6 px-6 py-3 mr-4'>Guardar Cambios</button>
                    <button  class='btn btn-secondary'>Cancelar</button>
                  </div> */}
                </div>
                <form class='form'>
                  <div class='card-body'>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>Nombres</label>
                      <div class='col-lg-9 col-xl-6'>
                        <div>
                          <input disabled class='form-control form-control-lg form-control-solid' type='text' value={user.nombre || ''} name='nombre' id='nombre' required />
                        </div>
                      </div>
                    </div>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>Apellidos</label>
                      <div class='col-lg-9 col-xl-6'>
                        <div>
                          <input disabled class='form-control form-control-lg form-control-solid' type='text' value={user.apellido || ''} name='apellido' id='apellido' required />
                        </div>
                      </div>
                    </div>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>Género</label>
                      <div class='col-lg-9 col-xl-6'>
                        <select disabled class='form-control form-control-lg form-control-solid' name='genero' id='genero' required=''>
                          <option value='masculino' selected={user.Sexo === 'M'}>Masculino</option>
                          <option value='femenino' selected={user.Sexo === 'F'}>Femenino</option>
                        </select>
                      </div>
                    </div>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>Fecha de nacimiento</label>
                      <div class='col-lg-9 col-xl-6'>
                        <input disabled class='form-control form-control-lg form-control-solid' type='text' value={user.fechaNacimiento ? user.fechaNacimiento.split('T')[0] : ''} name='fecha' id='fecha' required />
                      </div>
                    </div>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>Email</label>
                      <div class='col-lg-9 col-xl-6'>
                        <div>
                          <input disabled class='form-control form-control-lg form-control-solid' type='email' value={user.email || ''} name='email' id='email' required='' />
                        </div>
                      </div>
                    </div>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>Teléfono</label>
                      <div class='col-lg-9 col-xl-6'>
                        <div>
                          <input disabled class='form-control form-control-lg form-control-solid' type='phone' value={user.telefono || ''} name='telefono' id='telefono' required='' />
                        </div>
                      </div>
                    </div>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>Tipo de documento</label>
                      <div class='col-lg-9 col-xl-6'>

                        <select disabled class='form-control form-control-lg form-control-solid' name='tipodocumento' id='tipodocumento' required=''>
                          <option value='dni' selected={user.TipoDocumento === 'DNI'}>DNI</option>
                          <option value='le' selected={user.TipoDocumento === 'LE'}>LE</option>
                          <option value='lc' selected={user.TipoDocumento === 'LC'}>LC</option>
                          <option value='ci' selected={user.TipoDocumento === 'CI'}>CI</option>
                          <option value='not-selected'>Sin información</option>
                        </select>

                      </div>
                    </div>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>N° de documento</label>
                      <div class='col-lg-9 col-xl-6'>
                        <div>
                          <input disabled class='form-control form-control-lg form-control-solid' type='text' value={user.nroDocumento || ''} name='numerodocumento' id='numerodocumento' required='' />
                        </div>
                      </div>
                    </div>
                    <div class='form-group row'>
                      <label class='col-xl-3 col-lg-3 col-form-label'>Producto</label>
                      <div class='col-lg-9 col-xl-6'>
                        <input disabled class='form-control form-control-lg form-control-solid' type='text' value={producto.nombre || ''} name='producto' id='producto' required='' />
                        {/* <select disabled class='form-control form-control-lg form-control-solid' name='producto' id='producto' required=''>
                          <option value='producto1' selected=''>Producto 1</option>
                          <option value='producto1'>Producto 2</option>
                          <option value='producto1'>Producto 3</option>
                          <option value='producto1'>Producto 4</option>
                          <option value='producto1'>Producto 5</option>
                        </select> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </form>
            <div class='mt-9'>
              <ChangePassword email={user.email || ''} />
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}

export default Perfil
