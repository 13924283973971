import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { BtnTratamiento } from '../../btn-tratamiento'
import { Context } from '../../../Context'

import 'moment/locale/es'
const moment = require('moment')

const CalendarPlaceholder = ({ getTextByProductPlural }) => {
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='alert alert-secondary text-center' role='alert'>
          <h5 className='text-center mb-4'>Aún no tienes {getTextByProductPlural()} disponibles</h5>
          <hr style={{ borderColor: '#cdd1d4' }} />
          <p style={{ fontStyle: 'italic', fontSize: '1em' }}>Aquí aparecerá un resumen de tus {getTextByProductPlural()} cuando estén disponibles</p>
        </div>
      </div>
    </div>
  )
}

function CalendarSummary ({ medicines = [], nextMedicine = undefined }) {
  const { producto } = useContext(Context)
  const orderedMedicines = orderApplication(medicines, nextMedicine, producto)

  const getTextByProductPlural = () => {
    if (producto && (producto.id === 18)) {
      return 'tomas'
    }

    return 'aplicaciones'
  }

  const getTextByProductPasado2 = () => {
    if (producto && (producto.id === 18)) {
      return 'Tomada'
    }

    return 'Aplicada'
  }

  const getTextByProductImp = () => {
    if (producto && (producto.id === 18)) {
      return 'tomar'
    }

    return 'aplicar'
  }

  return (
    <div>
      <div class='card card-custom'>
        <div class='card-header'>
          <div class='card-title'>
            <h4 class='text-uppercase font-weight-boldest w-100 mt-1 mb-0'>{getTextByProductPlural()} de medicamento</h4>
          </div>
        </div>
        <div class='card-body'>
          <div className='container p-0'>
            <p class='text-black-50 font-size-h6 mb-8'>Aquí puede confirmar la {getTextByProduct(producto)} del medicamento haciendo click en el botón confirmar</p>
            {
              orderedMedicines.length === 0
                ? <CalendarPlaceholder getTextByProductPlural={getTextByProductPlural} />
                : orderedMedicines.map((medicine, index) => {
                  let motivo = getTextByProductPasado2()

                  if (medicine.taken === 'no') {
                    motivo = `No ${getTextByProductPasado2().toLowerCase()}: ${medicine.motivoNoRealizada}`
                  }

                  if (medicine.taken === 'next') {
                    motivo = 'Falta ' + getTextByProductImp()
                  }

                  return (
                    <div class='row pl-4 pr-4' key={index}>
                      <div className='col-md-12'>
                        <div className='row' style={{ background: '#F3F6F9', border: '1px solid rgb(236, 240, 243)' }}>
                          <div className='col-md-12 p-2 ml-4'>
                            <p className='mb-0' style={{ textTransform: 'uppercase' }}>
                              {moment(medicine.fecha).format('LL')}
                            </p>
                          </div>
                        </div>
                        <div className='row' style={{ padding: '0.75rem 1.25rem', border: '1px solid rgb(236, 240, 243)' }}>
                          <div className='col-1 p-0'>
                            {/* {moment(medicine.fecha).format('LT')} */}
                          </div>
                          <div className='col-1 p-0'>
                            <FontAwesomeIcon color={medicine.color} icon={faCircle} />
                          </div>
                          <div className='col-9 pl-5'>
                            {medicine.title} {medicine.taken === 'next' && <BtnTratamiento className='btn fc-list-button float-right' />}
                            <br /> <small className='text-muted'>{motivo}</small>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
                }
            <p class='text-black-50 font-size-base font-italic mt-7 mb-0'>Las {getTextByProductPlural()} son consideradas de acuerdo al prospecto del  medicamento o la indicación médica</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function getTextByProduct (producto) {
  if (producto && (producto.id === 18)) {
    return 'toma'
  }

  return 'aplicación'
}

function orderApplication (medicines, nextMedicine, producto) {
  const length = medicines.length
  const result = []
  let tomada = ''

  if (length >= 2) {
    tomada = medicines[length - 2].realizada ? 'si' : 'no'
    result.push(setMedicineProperties(medicines[length - 2], tomada, 'Anteúltima'))
  }

  if (length >= 1) {
    tomada = medicines[length - 1].realizada ? 'si' : 'no'
    result.push(setMedicineProperties(medicines[length - 1], tomada, 'Última'))
  }
  if (nextMedicine) {
    console.log('nextMedicine', nextMedicine)
    result.push(setMedicineProperties(nextMedicine, 'next', 'Próxima ' + getTextByProduct(producto)))
  }

  return result
}

function setMedicineProperties (medicine, taken, title) {
  let color = 'red'
  switch (taken) {
    case 'si':
      color = 'green'
      break
    case 'no':
      color = 'red'
      break
    case 'next':
      color = 'yellow'
      break

    default:
      break
  }

  return { ...medicine, color, taken, title }
}

export default CalendarSummary
