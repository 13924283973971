import React, { useState, useEffect } from 'react'

import { Link, useParams } from 'react-router-dom'
import Api from '../../util/Api'
import Loading from '../../components/Loading'

export const Encuesta = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
  const [encuesta, setEncuesta] = useState({})
  const [error, setError] = useState(null)
  const { idEncuesta } = useParams()

  const respuesta = {}

  useEffect(async () => {
    const encuestaResponse = await Api.get('/encuestas/' + idEncuesta)
    setEncuesta(encuestaResponse)
    setLoading(false)
  }, [])

  const onSubmit = async (e) => {
    setSending(true)
    e.preventDefault()
    console.log('respuesta', respuesta)
    const cantResponses = Object.keys(respuesta).length
    // const cantQuestions = getCantOfQuestions()

    const payload = {
      idEncuesta: idEncuesta,
      respuestas: respuesta
    }

    if (cantResponses >= 1) {
      await Api.post('/encuestas/usuario', payload)
      setShowSuccess(true)
    } else {
      setError('Debes contentar las preguntas')
    }
    setSending(false)
  }

  //   const getCantOfQuestions = () => {
  //     let response = 0
  //     for (const agrupaciones of encuesta.agrupaciones) {
  //       response = response + agrupaciones.preguntas.length
  //     }

  //     return response
  //   }

  const addResponse = (e) => {
    e.preventDefault()
    respuesta[e.target.name] = e.target.value

    if (error !== null) {
      setError(null)
    }

    if (e.target.value === '0') {
      delete respuesta[e.target.name]
    }
  }

  const addMultipleResponse = (e) => {
    console.log(e.target.name)
    console.log(e.target.value)
    console.log(e.target.checked)
    if (e.target.checked) {
      if (!respuesta[e.target.name]) {
        respuesta[e.target.name] = [e.target.value]
      } else {
        respuesta[e.target.name] = [...respuesta[e.target.name], e.target.value]
      }
    } else {
      respuesta[e.target.name] = removeItem(respuesta[e.target.name], e.target.value)

      if (respuesta[e.target.name].length === 0) {
        delete respuesta[e.target.name]
      }
    }
  }

  const removeItem = (array, value) => {
    const index = array.indexOf(value)
    if (index > -1) {
      array.splice(index, 1)
    }
    return array
  }

  if (loading) {
    return (
      <div class='d-flex flex-column-fluid py-15 h-1000px' style={{ backgroundColor: '#eef0f8' }}>
        <div class='container'>
          <div class='d-flex flex-row'>
            <Loading size='30' className='ml-auto mr-auto' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='d-flex flex-column-fluid py-15' style={{ backgroundColor: '#eef0f8' }}>
      <div className='container'>
        <div className='card card-custom'>
          {
            !showSuccess
              ? (
                <>
                  <div className='card-header'>
                    <h3 className='card-title encuesta__title'>{encuesta.nombre}</h3>
                    <p> {encuesta.descripcion} </p>
                    <p> {encuesta.descripcion2} </p>
                  </div>
                  <form className='form'>
                    <div className='card-body'>
                      {/* asd */}
                      {/* <div class='form-group mb-10'>
                        <div class='d-flex align-items-start mb-4'>
                          <span class='svg-icon svg-icon-primary mr-2' />
                          <p class='mb-0'>¿Ipsa nobis distinctio autem nulla, excepturi quam nam laborum quia ipsam, molestiae repellendus! Nesciunt blanditiis reprehenderit, enim nihil deserunt molestiae harum aliquid?</p>
                        </div>
                        <div class='checkbox-list ml-8' name='asd'>
                          <label class='checkbox'>
                            <input type='checkbox' name='checkboxes1' value='1' onClick={test} />
                            <span />Opción 1
                          </label>
                          <label class='checkbox'>
                            <input type='checkbox' name='checkboxes2' value='2' onClick={test} />
                            <span />Opción 2
                          </label>
                          <label class='checkbox'>
                            <input type='checkbox' name='checkboxes3' />
                            <span />Opción 3
                          </label>
                        </div>
                      </div> */}
                      {/* asd */}
                      {
                            encuesta.agrupaciones.map((agrupacion, key) => {
                              return (
                                <div key={key} className='form-group mb-10 '>
                                  <p className='encuesta__msg'> {agrupacion.texto} </p>
                                  {
                                        agrupacion.preguntas.map((pregunta, key) => {
                                          return (
                                            <div key={key} className='mb-8'>
                                              <div className='d-flex align-items-start mb-1 mt-3'>
                                                <Icon />
                                                <p className='mb-0'> {pregunta.texto} </p>
                                              </div>
                                              {
                                                pregunta.tipo === 1 &&
                                                  <select className='form-control' onChange={addResponse} name={pregunta.id}>
                                                    <option value='0'> Seleccione una respuesta </option>
                                                    {
                                                        pregunta.opciones.map((opcion, key) => (
                                                          <option value={opcion.id} key={key}> {opcion.texto} </option>
                                                        ))
                                                    }
                                                  </select>
                                              }
                                              {
                                                pregunta.tipo === 2 &&
                                                  <div class='checkbox-list ml-8' name='asd'>
                                                    {
                                                        pregunta.opciones.map((opcion, key) => (
                                                          <label class='checkbox' key={key}>
                                                            <input type='checkbox' name={pregunta.id} value={opcion.id} onClick={addMultipleResponse} />
                                                            <span />{opcion.texto}
                                                          </label>
                                                        ))
                                                    }
                                                  </div>
                                              }
                                            </div>
                                          )
                                        })
                                    }
                                </div>
                              )
                            })
                        }
                    </div>
                    <div className='card-footer'>
                      <button type='button' onClick={onSubmit} disabled={sending} className='btn btn-primary mr-2'>
                        {sending ? <Loading /> : 'Enviar'}
                      </button>
                      {
                          error !== null && <small style={{ color: 'red' }}> {error} </small>
                      }

                    </div>
                  </form>
                  {/* <form className='form' onSubmit={onSubmit}>
                    <div className='card-body'>

                      <div className='form-group select-lg mb-10'>
                        <p className='encuesta__msg'>Preguntas dirigidas a pacientes en tratamiento con mepolizumab (Nucala)</p>
                        <div className='d-flex align-items-start mb-4'>
                          <Icon />
                          <p className='mb-0'> ¿Le gustaría recibir una charla virtual sobre información médica y consejos útiles del manejo de su enfermedad?</p>
                        </div>
                        <div className='radio-list ml-8'>
                          <label class='radio'>
                            <input type='radio' name='radio1' required />
                            <span />si
                          </label>
                          <label class='radio'>
                            <input type='radio' name='radio1' required />
                            <span />no
                          </label>
                        </div>
                      </div>
                      <div className='form-group select-lg mb-10'>
                        <div className='d-flex align-items-start mb-4'>
                          <Icon />
                          <p className='mb-0'>Si la respuesta es afirmativa responda a la siguiente pregunta: ¿Qué tipo de información le gustaría recibir durante la charla virtual?</p>
                        </div>
                        <select className='form-control'>
                          <option value='' selected disabled>Seleccionar una opción</option>
                          <option value='¿Qué hacer ante una crisis de asma?'>¿Qué hacer ante una crisis de asma?</option>
                          <option value='¿Cómo saber si el asma está controlada?'>¿Cómo saber si el asma está controlada?</option>
                          <option value='¿Es para toda la vida?'>¿Es para toda la vida?</option>
                          <option value='Asma y ejercicio'>Asma y ejercicio</option>
                          <option value='¿Cómo influye el medioambiente en el asma?'>¿Cómo influye el medioambiente en el asma?</option>
                          <option value='Asma y maternidad.'>Asma y maternidad.</option>
                          <option value='Asma y nutrición.'>Asma y nutrición.</option>
                          <option value='SugerenciasPreguntas '>SugerenciasPreguntas </option>
                        </select>
                      </div>
                      <div className='form-group select-lg mb-10'>
                        <p className='encuesta__msg'>Preguntas dirigidas a pacientes en tratamiento con belimumab (Benlysta)</p>
                        <div className='d-flex align-items-start mb-4'>
                          <Icon />
                          <p className='mb-0'>¿Le gustaría recibir una charla virtual dada por un profesional especialista sobre información médica y consejos útiles del manejo de su enfermedad?</p>
                        </div>
                        <div className='radio-list ml-8'>
                          <label class='radio'>
                            <input type='radio' name='radio2' required />
                            <span />si
                          </label>
                          <label class='radio'>
                            <input type='radio' name='radio2' required />
                            <span />no
                          </label>
                        </div>
                      </div>
                      <div className='form-group select-lg mb-10'>
                        <div className='d-flex align-items-start mb-4'>
                          <Icon />
                          <p className='mb-0'> Si la respuesta es afirmativa responda a la siguiente pregunta: ¿Qué tipo de información le gustaría recibir durante la charla virtual?</p>
                        </div>
                        <select className='form-control'>
                          <option value='' selected disabled>Seleccionar una opción</option>
                          <option value='Lupus y Sexualidad.'>Lupus y Sexualidad.</option>
                          <option value='Lupus y Maternidad.'>Lupus y Maternidad.</option>
                          <option value='Lupus y desarrollo profesional.'>Lupus y desarrollo profesional.</option>
                          <option value='Lupus y vida cotidiana.'>Lupus y vida cotidiana.</option>
                          <option value='Lupus y vacaciones.'>Lupus y vacaciones.</option>
                          <option value='Lupus y cuidado del sol.'>Lupus y cuidado del sol.</option>
                          <option value='Lupus en jóvenes adultos.'>Lupus en jóvenes adultos.</option>
                          <option value='Sugerencias'>Sugerencias</option>
                        </select>
                      </div>
                    </div>
                    <div className='card-footer'>
                      <button type='submit' className='btn btn-primary mr-2'>Enviar</button>
                    </div>
                  </form> */}
                </>
                )
              : (
                <>
                  <div className='card-header' style={{ justifyContent: 'center' }}>
                    <h3 className='card-title encuesta__title'>Gracias por contestar la encuesta</h3>
                  </div>
                  <div style={{ padding: '16px', textAlign: 'center' }}>
                    <Link to='/' className='btn btn-primary mr-2'>Volver</Link>
                  </div>
                </>
                )
          }
        </div>
      </div>
    </div>
  )
}

const Icon = () => {
  return (
    <span className='svg-icon svg-icon-primary mr-2'>
      <svg xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' width='24px' height='24px' viewBox='0 0 24 24' version='1.1'>
        <g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'>
          <polygon points='0 0 24 0 24 24 0 24' />
          <path d='M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z' fill='#000000' fill-rule='nonzero' />
          <path d='M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z' fill='#000000' fill-rule='nonzero' opacity='0.3' transform='translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999)' />
        </g>
      </svg>
    </span>
  )
}
