import api from '../util/Api'

export const pacienteService = {
  getPrograma () {
    return api.get('/pacientes/me/programas')
  },
  setMedication ({ takenDate, taken, reason = undefined }) {
    const requestBody = {
      fecha: takenDate,
      tomada: taken,
      ...reason && { motivo: reason }
    }
    return api.post('/pacientes/me/aplicaciones', requestBody)
  },
  changePassword (body) {
    return api.put('/pacientes/me/change-password', body)
  },
  login (email, pass) {
    return api.post('/login', { email, pwd: pass })
  }
}
