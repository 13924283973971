import { listMatch } from './data'
export const search = (word = '') => {
  const wordSplit = word.split(' ').filter(x => x !== '' && x !== ' ')
  const marchSearch = []
  wordSplit.forEach(word => {
    const regexWord = new RegExp(`${word}`, 'i')
    listMatch.forEach(item => {
      for (let index = 0; index < item.tags.length; index++) {
        const tag = item.tags[index]
        if (regexWord.test(tag)) {
          marchSearch.push(item)
          break
        }
      }
    })
  })
  return marchSearch
}
