import React, { useState, useContext } from 'react'
import { ModalTratamiento } from '../modal-tratamiento'
import { Context } from '../../Context'

export const BtnTratamiento = ({ className }) => {
  const { nextApplying } = useContext(Context)
  const [show, setShow] = useState(false)

  const showButton = !!nextApplying

  const onClose = () => {
    setShow(false)
  }
  return (
    <>
      {showButton && <button className={className} onClick={() => setShow(true)}>CONFIRMAR</button>}
      {show ? <ModalTratamiento onClose={onClose} onRegister={onClose} /> : null}
    </>
  )
}
