import React from 'react'
import { Footer } from '../../components/footer'
import { Header } from '../../components/header'
import { SubHeader } from '../../components/sub-header'
import { HeaderMobile } from '../../components/header/header-mobile'

export const Layout = ({ children }) => {
  return (
    <>
      <HeaderMobile />
      <div className='d-flex flex-column flex-root'>
        <div className='d-flex flex-row flex-column-fluid page'>
          <div
            className='d-flex flex-column flex-row-fluid wrapper'
            id='kt_wrapper'
          >
            <Header />
            <div
              className='content d-flex flex-column flex-column-fluid'
              id='kt_content'
            >
              <SubHeader />
              {children}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}
