export default {
  baseUrlApi2: 'https://api-programamas-com-ar.softlaunch-apache.gskinternet.com',
  baseUrlPublic2: 'programamas-com-ar.softlaunch-apache.gskinternet.com',
  apiPharmexUrl2: 'https://adherence.pharmexxarg.com/a/ext',
  domain2: '.gskinternet.com',
  baseUrlApi: 'https://api.programamas.com.ar',
  baseUrlPublic: 'https://programamas.com.ar',
  apiPharmexUrl: 'https://adherence.pharmexxarg.com/a/ext',
  domain: '.programamas.com.ar'
}
