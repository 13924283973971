import React from 'react'
import { useForm } from 'react-hook-form'

export const Filter = (props) => {
  const { register, handleSubmit } = useForm()
  return (
    <div className='mb-10'>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className='row align-items-center'>
          <div className='col-lg-12 col-xl-10'>
            <div className='row align-items-center'>
              <div className='col-md-4 my-2 my-md-0'>
                <div className='input-icon'>
                  <input name='word' ref={register} type='text' className='form-control form-control-solid' placeholder='Buscar..' />
                  <span>
                    <i className='flaticon2-search-1 text-muted' />
                  </span>
                </div>
              </div>
              <div className='col-md-4 my-2 my-md-0'>
                <select ref={register} name='typeFile' defaultValue='' className='form-control form-control-solid'>
                  <option value=''>Categorias (todas)</option>
                  {props.listTypeFile.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className='col-lg-12 col-xl-2 mt-5 mt-lg-0'>
            <button type='submit' className='btn w-100 btn-light-primary px-6 font-weight-bold'>Buscar</button>
          </div>
        </div>
      </form>
    </div>
  )
}
