import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../components/Loading'
import CalendarSummary from '../../components/home/CalendarSummary'
import Consentimiento from '../../components/home/Consentimiento'
import Doctor from '../../components/home/Doctor'
import Reminder from '../../components/home/Reminder'
import { pacienteService } from '../../services/paciente.service'

const Home = () => {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})
  const [medicines, setMedicines] = useState([])
  const [nextMedicine, setNextMedicine] = useState({})

  useEffect(async function () {
    setLoading(true)
    const userData = await pacienteService.getPrograma()
    setMedicines(userData.calendarioAplicaciones || [])
    setNextMedicine(userData.proximaAplicacion || undefined)
    setUser(userData || {})
    setLoading(false)
  }, [])

  if (loading) {
    return (
      <div class='d-flex flex-column-fluid py-15 h-1000px' style={{ backgroundColor: '#eef0f8' }}>
        <div class='container'>
          <div class='d-flex flex-row'>
            <Loading size='30' className='ml-auto mr-auto' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div class='d-flex flex-column bg-light h-100 py-10 py-lg-15'>
      <div class='container'>
        <Consentimiento />
        <div class='row'>
          <div class='col-12 col-lg-6 order-1 order-lg-0'>
            <div class='card card-custom h-100'>
              <div class='card-body'>
                <Doctor doctor={user.medico} />
                <div class='separator separator-solid my-20' />
                <Reminder nextMedicine={nextMedicine} />
              </div>
            </div>
          </div>

          <div class='col-12 col-lg-6 mb-10 mb-lg-0 order-0 order-lg-1'>
            <CalendarSummary medicines={medicines} nextMedicine={nextMedicine} />
          </div>
        </div>
        <div class='row'>
          <div class='col-12 order-2 mt-7'>
            <div class='card card-custom card-stretch gutter-b'>
              <div class='card-body'>
                <Link to='/servicios' class='card-link'>
                  <div class='card card-custom'>
                    <div class='card-body rounded p-0 d-flex header-materiales' style={{ backgroundColor: '#F36633' }}>
                      <div class='d-flex flex-column flex-lg-row-auto text-center text-lg-left w-100 w-lg-350px w-xl-450px w-xxl-500px p-5 p-md-20'>
                        <h1 class='font-weight-bolder'>Servicios del programa</h1>
                      </div>
                      <div class='d-none d-lg-flex flex-row-fluid bgi-no-repeat bgi-position-y-center bgi-position-x-left bgi-size-cover' style={{ backgroundImage: 'url("http://client.elpixel.net/gsk/portal-gsk/assets/media/bg/IMG_5725.jpg")' }} />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
  )
}

export default Home
