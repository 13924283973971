import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loading from '../../components/Loading'
import { pacienteService } from '../../services/paciente.service'

export const AccesoMedicacion = () => {
  const [loading, setLoading] = useState(true)
  const [indicacion, setIndicacion] = useState(null)
  const [financiador, setFinanciador] = useState(false)

  useEffect(async () => {
    try {
      const response = await pacienteService.getPrograma()
      console.log('a', response)
      setIndicacion(response.indicacion || {})
      setFinanciador(response.financiador || {})
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }, [])

  if (loading) {
    return (
      <div className='d-flex flex-column-fluid py-15 h-1000px'>
        <div className='container'>
          <div className='d-flex flex-row'>
            <Loading size='30' className='ml-auto mr-auto' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='d-flex flex-column bg-light h-100 py-10 py-lg-15'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <div className='card card-custom mb-8'>
              <div className='card-body'>
                <h4 className='font-weight-boldest w-100 mb-8'>Conozca cuales son los requisitos/documentación necesaria para poder iniciar los trámites de acceso para su medicación</h4>
                <h5 className='font-weight-boldest w-100 mb-3'>Requisitos Generales</h5>
                <ul className='mb-8'>
                  <li>Fotocopia de la credencial de su obra social.</li>
                  <li>Fotocopia del DNI del afiliado.</li>
                  <li>Prescripción del medicamento sellada y firmada por el médico tratante (receta médica).</li>
                  <li>Resumen de historia clínica con el detalle del cuadro clínico firmada y sellada por su médico tratante.</li>
                  <li>Justificación médica de la indicación del medicamento, informando dosis utilizada, tiempo y respuesta, firmada y sellada por el médico especialista tratante.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-lg-6'>
            <div className='card card-custom mb-8'>
              <form className='form'>
                <div className='card-body'>
                  <div className='form-group'>
                    <label>Indicación</label>
                    <div />
                    <input name='financiador' id='financiador' type='text' className='form-control form-control-lg text-primary' placeholder='Financiador' value={indicacion.nombre || ''} required readOnly />
                  </div>
                  <div className='form-group'>
                    <label>Obra social</label>
                    <a href={financiador.url || '#'} className='financiador-link d-flex justify-content-end align-items-center' rel='noreferrer' target='_blank'>
                      <span className='svg-icon svg-icon-lg mr-6'>
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72.28 72.28'><g id='Layer_2' data-name='Layer 2'><g id='Layer_1-2' data-name='Layer 1'><path d='M36.14,0A36.14,36.14,0,1,0,72.28,36.14,36.14,36.14,0,0,0,36.14,0Zm0,66.26A30.12,30.12,0,1,1,66.26,36.14,30.15,30.15,0,0,1,36.14,66.26ZM33.75,19a3,3,0,1,0-4.26,4.26L42.42,36.14,29.49,49.07a3,3,0,0,0,4.26,4.26L48.81,38.27a3,3,0,0,0,0-4.26Z' style={{ fillRule: '#ec6737' }} /></g></g></svg>
                      </span>
                    </a>
                    <input name='financiador' id='financiador' type='text' className='form-control form-control-lg text-primary' placeholder='Financiador' value={financiador.nombre || ''} required />
                  </div>
                </div>
              </form>
            </div>
            <div className='card card-custom mb-8'>
              <div className='card-body d-flex align-items-center justify-content-between'>
                <p className='mb-0 mr-5'>En caso de que la cobertura buscada no esté, por favor contáctese con nosotros.</p>
                <Link to='/servicios/apoyo-y-seguimiento' className='btn btn-bg-primary btn-hover-secondary btn-icon mr-3 my-2 my-lg-0 px-4'>
                  <span className='svg-icon svg-icon-white svg-icon-md'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72.28 52.82'>
                      <g id='Layer_2' data-name='Layer 2'>
                        <g id='Layer_1-2' data-name='Layer 1'>
                          <path d='M66.26,0H6A6,6,0,0,0,0,6V46.79a6,6,0,0,0,6,6H66.26a6,6,0,0,0,6-6V6A6,6,0,0,0,66.26,0ZM37.81,27.15a2.7,2.7,0,0,1-3.51,0L11.35,6H60.9Zm-14.6-2L6,42.31v-33Zm4.44,4.09,2.57,2.37a8.78,8.78,0,0,0,11.66,0l2.69-2.46L62.23,46.79H10.05ZM49,25.06,66.26,9.29v33Z' fill='#ffffff' fill-rule='nonzero' />
                        </g>
                      </g>
                    </svg>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
