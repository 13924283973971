import React from 'react'
import { Link } from 'react-router-dom'
import { Notice } from './components/notice'
import { Seacth } from './components/search'
import { Notification } from './components/notification'

export const SubHeader = () => {
  return (
    <div className='subheader pt-2 pb-0 pt-lg-6 pb-lg-6 subheader-solid flex-column flex-lg-row mb-0' id='kt_subheader'>
      <div className='container-fluid d-flex align-items-center justify-content-center justify-content-lg-end flex-wrap flex-sm-nowrap px-3 px-lg-4'>
        <div className='d-flex align-items-center flex-wrap'>
          <Link to='/calendario' className='btn btn-bg-white btn-icon-info btn-hover-primary btn-icon mr-3 my-2 my-lg-0'>
            <span className='svg-icon svg-icon-md'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72.59 81.89'>
                <g id='Layer_2' data-name='Layer 2'>
                  <g id='Layer_1-2' data-name='Layer 1'>
                    <path d='M72.59,22.52H0V15.3A6.61,6.61,0,0,1,6.57,8.67H17.23v2.12a5.22,5.22,0,0,0-2.62,4.51,5.11,5.11,0,1,0,10.22,0,5.22,5.22,0,0,0-2.62-4.51V8.67H50.37v2.12a5.22,5.22,0,0,0-2.62,4.51A5.11,5.11,0,1,0,58,15.3a5.22,5.22,0,0,0-2.62-4.51V8.67H66a6.62,6.62,0,0,1,6.57,6.63v7.22ZM0,25.39H72.59V75.27A6.61,6.61,0,0,1,66,81.89H6.57A6.6,6.6,0,0,1,0,75.27V25.39Zm54.21,18H67.05V31H54.21V43.4Zm0,16.34H67.05V47.31H54.21V59.74Zm0,16.31H67.05V63.65H54.21v12.4ZM38,43.4H50.85V31H38V43.4Zm0,16.34H50.85V47.31H38V59.74Zm0,16.31H50.85V63.65H38v12.4ZM21.81,43.4H34.65V31H21.81V43.4Zm0,16.34H34.65V47.31H21.81V59.74Zm0,16.31H34.65V63.65H21.81v12.4ZM5.61,43.4H18.45V31H5.61V43.4Zm0,16.34H18.45V47.31H5.61V59.74Zm0,16.31H18.45V63.65H5.61v12.4ZM17.23,15.41a2.51,2.51,0,0,0,2.51,2.49,2.47,2.47,0,0,0,2.47-2.49V2.49A2.48,2.48,0,0,0,19.74,0a2.52,2.52,0,0,0-2.51,2.49V15.41Zm33.14,0a2.49,2.49,0,1,0,5,0V2.49a2.49,2.49,0,1,0-5,0V15.41Z' fill='#000000' fillRule='nonzero' />
                  </g>
                </g>
              </svg>
            </span>
          </Link>
          <div data-toggle='tooltip' title='' data-placement='left' data-original-title='Pedido de contacto'>
            <Link to='/servicios/apoyo-y-seguimiento' className='btn btn-bg-white btn-icon-success btn-hover-primary btn-icon mr-3 my-2 my-lg-0'>
              <span className='svg-icon svg-icon-md'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72.28 52.82'>
                  <g id='Layer_2' data-name='Layer 2'>
                    <g id='Layer_1-2' data-name='Layer 1'>
                      <path d='M66.26,0H6A6,6,0,0,0,0,6V46.79a6,6,0,0,0,6,6H66.26a6,6,0,0,0,6-6V6A6,6,0,0,0,66.26,0ZM37.81,27.15a2.7,2.7,0,0,1-3.51,0L11.35,6H60.9Zm-14.6-2L6,42.31v-33Zm4.44,4.09,2.57,2.37a8.78,8.78,0,0,0,11.66,0l2.69-2.46L62.23,46.79H10.05ZM49,25.06,66.26,9.29v33Z' fill='#000000' fillRule='nonzero' />
                    </g>
                  </g>
                </svg>
              </span>
            </Link>
          </div>
          <Notification />
        </div>
        <Seacth />
      </div>
      <Notice />
    </div>
  )
}
