import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'

function Home () {
  return (
    <div class='d-flex flex-column-fluid py-15'>
      <div class='container text-center'>

        <svg
          class='ruedaHome' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
          viewBox='0 0 1272 1218' style={{ enableBackground: 'new 0 0 1272 1218' }} space='preserve'
        >

          <Link to='/servicios/asesoramiento-territorial'>
            <g class='itemRueda' id='asesoramiento2'>
              <g>
                <g>
                  <linearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='1070.235' y1='325.5274' x2='651.5771' y2='618.6748'>
                    <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
                    <stop offset='1' style={{ stopColor: '#F68C28' }} />
                  </linearGradient>
                  <path class='st0' d='M638.8,600.4v-511c282.2,0,511,228.8,511,511c0,94-21.5,174.1-68.5,255.5L638.8,600.4z' />
                </g>
              </g>
              <g>
                <path
                  class='st1' d='M1076.5,264.1c-40,0-72.4,32.4-72.4,72.4s32.4,72.4,72.4,72.4c40,0,72.4-32.4,72.4-72.4
                S1116.5,264.1,1076.5,264.1'
                />
                <path
                  class='st2' d='M1109.8,357.2c0,0.8-0.6,1.4-1.4,1.4h-42.5c-0.8,0-1.4-0.6-1.4-1.4v-57.9c0-0.8,0.6-1.4,1.4-1.4h42.5c0.8,0,1.4,0.6,1.4,1.4V357.2z'
                />
                <path
                  class='st2' d='M1088.6,373.6c0,0.8-0.6,1.4-1.4,1.4h-42.5c-0.8,0-1.4-0.6-1.4-1.4v-57.9c0-0.8,0.6-1.4,1.4-1.4h16.9v8.2h-10.1v5.8h10.1v2.9h-10.1v5.8h10.1v20.3c0,2.4,1.9,4.3,4.3,4.3h22.7V373.6z'
                />
                <rect x='1067.4' y='300.8' class='st1' width='39.6' height='55' />
                <rect x='1072.7' y='347.4' class='st2' width='10.1' height='2.9' />
                <rect x='1072.7' y='341.6' class='st2' width='20.3' height='2.9' />
                <rect x='1072.7' y='335.8' class='st2' width='20.3' height='2.9' />
                <rect x='1072.7' y='314.7' class='st2' width='20.3' height='5.8' />
                <rect x='1072.7' y='306.1' class='st2' width='28.9' height='5.8' />
                <rect x='1051.5' y='364.4' class='st1' width='9.6' height='2.9' />
                <rect x='1004.2' y='264.1' class='st3' width='144.7' height='144.7' />
              </g>
              <g>
                <text transform='matrix(1 0 0 1 899.4102 471.9761)'><tspan x='0' y='0' class='st4 st5'>Asesoramiento </tspan><tspan x='32' y='36' class='st4 st5'>en Acceso</tspan></text>
              </g>
            </g>
          </Link>

          <Link to='/servicios/aplicacion-en-domicilio'>
            <g class='itemRueda' id='aplicacionDomicilio'>
              <linearGradient id='SVGID_2_' gradientUnits='userSpaceOnUse' x1='858.0503' y1='1111.5605' x2='858.0503' y2='600.4321'>
                <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
                <stop offset='1' style={{ stopColor: '#FDBB31' }} />
              </linearGradient>
              <path class='st6' d='M814.5,1078.6c107.5-40,202.3-116.2,264.2-223.3L638.1,600.8l-0.7,0.4L814.5,1078.6z' />
              <g>
                <path
                  class='st1' d='M975.5,915.8c-36.8,0-66.6,29.8-66.6,66.6s29.8,66.6,66.6,66.6s66.6-29.8,66.6-66.6S1012.3,915.8,975.5,915.8'
                />
                <path
                  class='st2' d='M930.6,966.2l43.9-25.3c1.1-0.6,2.5-0.6,3.6,0l43.9,25.3c1.7,1,2.3,3.2,1.3,4.9c-0.7,1.1-1.9,1.8-3.1,1.8c-0.6,0-1.2-0.2-1.8-0.5L976.3,948l-42.1,24.3c-1.7,1-3.9,0.4-4.9-1.3C928.4,969.3,928.9,967.1,930.6,966.2'
                />
                <g>
                  <path
                    class='st2' d='M974.7,971.5v3.3h-3.3c-1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9h3.3v3.3c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9v-3.3h3.3c1,0,1.9-0.8,1.9-1.9s-0.8-1.9-1.9-1.9h-3.3v-3.3c0-1-0.8-1.9-1.9-1.9C975.5,969.6,974.7,970.5,974.7,971.5z'
                  />
                  <path
                    class='st2' d='M996.5,991l2-17.8l-0.5-0.3c-6.4-4.3-13.4-6.5-20.7-6.5c-11.7,0-20,5.5-20.4,5.7l-0.5,0.3l2,16.5c-1.7,2.8-6.9,13.1-0.9,27H970c2.3,1.2,4.9,1.9,7.7,1.9c2.7,0,5.3-0.7,7.7-1.9h12.4C997.7,1016,1003.6,999.4,996.5,991zM977.6,1015.6c-9.1,0-16.5-8.2-16.5-18.3c0-1.6,0.2-3.1,0.5-4.6c3,0.2,10.7,0,14-6.3c0.1,0,0.3,0,0.4,0c2,1.5,13.4,9.9,17.9,8.7c0.1,0.7,0.1,1.4,0.1,2.2C994.1,1007.4,986.8,1015.6,977.6,1015.6z M994.6,990.4c-3.3-1.8-12.2-6.1-21.6-6.1c-4.8,0-9.1,1.2-12.7,3.4l-1.8-14.3c2-1.2,9.3-5.1,18.8-5.1c6.7,0,13.2,2,19.1,5.8L994.6,990.4z'
                  />
                </g>
              </g>
              <g>
                <text transform='matrix(1 0 0 1 796.1885 869.5762)'><tspan x='0' y='0' class='st4 st5'>Aplicación </tspan><tspan x='-14' y='36' class='st4 st5'>en domicilio</tspan></text>
              </g>
            </g>
          </Link>

          <Link to='/servicios/aplicacion-en-centros'>
            <g class='itemRueda' id='aplicacionCentros'>
              <linearGradient id='SVGID_3_' gradientUnits='userSpaceOnUse' x1='638.0568' y1='1111.5605' x2='638.0568' y2='600.4321'>
                <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
                <stop offset='1' style={{ stopColor: '#FDBB31' }} />
              </linearGradient>
              <path
                class='st7' d='M812.4,1079.4L637.4,601.2l-0.9,0.5l-172.8,478.9c12.8,4.7,25.7,8.8,38.7,12.4C606.1,1121.2,714.4,1115.3,812.4,1079.4z'
              />
              <g>
                <path
                  class='st1' d='M641.5,1044.8c-36.8,0-66.6,29.8-66.6,66.6s29.8,66.6,66.6,66.6s66.6-29.8,66.6-66.6S678.3,1044.8,641.5,1044.8'
                />
                <path
                  class='st2' d='M594.9,1096.2l43.9-25.3c1.1-0.6,2.5-0.6,3.6,0l43.9,25.3c1.7,1,2.3,3.2,1.3,4.9c-0.7,1.1-1.9,1.8-3.1,1.8c-0.6,0-1.2-0.2-1.8-0.5l-42.1-24.3l-42.1,24.3c-1.7,1-3.9,0.4-4.9-1.3C592.6,1099.4,593.2,1097.2,594.9,1096.2'
                />
                <path
                  class='st2' d='M670.4,1143.2l-2.6,2.6l-5.5-5.5l9.2-9.2c0.8-0.8,0.8-2.1,0-2.9c-0.8-0.8-2.1-0.8-2.9,0l-3.4,3.4l-31.3-31.3c-0.8-0.8-2.1-0.8-2.9,0l-5.1,5.1l-6.8-6.8c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l6.8,6.8l-5.1,5.1c-0.8,0.8-0.8,2.1,0,2.9l31.3,31.3l-3.4,3.4c-0.8,0.8-0.8,2.1,0,2.9c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,1-0.2,1.4-0.6l9.2-9.2l5.5,5.5l-2.6,2.6c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l8.1-8.1c0.4-0.4,0.4-1,0-1.5C671.5,1142.8,670.8,1142.8,670.4,1143.2 M634.1,1123.6l3.6-3.6c0.4-0.4,0.4-1.1,0-1.4c-0.4-0.4-1-0.4-1.4,0l-3.6,3.6l-8.8-8.8l8.7-8.7l13.1,13.1l-8.7,8.7L634.1,1123.6z M639.9,1129.4l3.6-3.6c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1.1,0,1.5l-3.6,3.6L639.9,1129.4z M644.2,1133.8l3.6-3.6c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.5l-3.6,3.6L644.2,1133.8z M653.7,1136l-3.6,3.6l-1.4-1.5l3.6-3.6c0.4-0.4,1-0.4,1.4,0C654.1,1134.9,654.1,1135.6,653.7,1136'
                />
              </g>
              <g>
                <text transform='matrix(1 0 0 1 565.3887 966.9248)'><tspan x='25' y='0' class='st8 st5'>Centros </tspan><tspan x='-15' y='36' class='st8 st5'>de Aplicación</tspan></text>
              </g>
            </g>
          </Link>

          <Link to='/servicios/centro-de-infusion'>
            <g class='itemRueda' id='infusion'>
              <linearGradient id='SVGID_4_' gradientUnits='userSpaceOnUse' x1='416.3917' y1='1111.5605' x2='416.3917' y2='600.4321'>
                <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
                <stop offset='1' style={{ stopColor: '#FDBB31' }} />
              </linearGradient>
              <path class='st9' d='M196.2,856c47,81.4,105.7,140.1,187.1,187.1c26.1,15,52.9,27.5,80.2,37.5l173.1-478.8L196.2,856z' />
              <g>
                <circle class='st1' cx='309.2' cy='994.1' r='57.1' />
                <path
                  class='st2' d='M356.8,995.2c0-26.4-21.5-47.9-47.9-47.9c-26.4,0-47.9,21.5-47.9,47.9c0,24,17.8,44,40.9,47.3l3.7,8.1c0,0,0,0.1,0,0.1l0,0h0c0.5,0.9,1.5,1.5,2.7,1.5c1.2,0,2.2-0.6,2.7-1.5h0l3.7-8C338.4,1039.8,356.8,1019.6,356.8,995.2zM322.9,994.8l-14.3,0l0-12.5l14.3,0L322.9,994.8z M303.3,977l0,23.1l19.6,0l0,11c0,0.1,0,0.1,0,0.2c0,1.2-2,3-6.2,4.2c-0.6,0.2-1.1,0.6-1.3,1.1c-0.5-0.4-1.1-0.5-1.7-0.4c-1.3,0.2-2.6,0.3-3.9,0.4c-0.6,0-1.2,0.3-1.5,0.7c-0.4-0.4-0.9-0.7-1.5-0.7c-8.1-0.3-13-3.3-13-5.2c0,0,0-0.1,0-0.1l0-40.8c0-0.4,0.3-0.6,0.6-0.6l27.8,0c0.4,0,0.6,0.3,0.6,0.6l0,6.7L303.3,977zM311,1037.7l0-9.4c0.6-0.4,1.1-1,1.1-1.8v-2.6c0.3,0.8,1.1,1.4,2,1.4h3.2c1.2,0,2.1-0.9,2.1-2.1v-4.2c4.8-1.7,7.6-4.4,7.7-7.6c0-0.1,0-0.2,0-0.2l0-40.8c0,0,0,0,0,0c0-2.7-2.2-4.9-4.9-4.9l-27.8,0c-2.7,0-4.9,2.2-4.9,4.9l0,40.8c0,0,0,0.1,0,0.1c0.1,4.7,6.1,8.4,15,9.2l0,6c0,0.8,0.4,1.5,1.1,1.8l0,9.3c-22-1.6-39.4-20-39.4-42.4c0-23.5,19.1-42.6,42.6-42.6c23.5,0,42.6,19.1,42.6,42.6C351.5,1017.9,333.5,1036.6,311,1037.7z'
                />
              </g>
              <g>
                <text transform='matrix(1 0 0 1 356.7466 861.4961)'><tspan x='0' y='0' class='st8 st5'>Centro </tspan><tspan x='-31.2' y='36' class='st8 st5'>de infusión</tspan></text>
              </g>
            </g>

          </Link>

          <Link to='/servicios/apoyo-y-seguimiento'>
            <g id='apoyo' class='itemRueda'>
              <linearGradient id='SVGID_5_' gradientUnits='userSpaceOnUse' x1='88.1821' y1='495.6119' x2='506.904' y2='788.8041'>
                <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
                <stop offset='1' style={{ stopColor: '#808080' }} />
              </linearGradient>
              <path class='st10' d='M638.5,596.3L171,394.6c-63.2,143.9-59.4,314.9,25.2,461.4l442.6-255.5' />
              <g>
                <path
                  class='st1' d='M138.5,542.8c-36.8,0-66.6,29.8-66.6,66.6s29.8,66.6,66.6,66.6c36.8,0,66.6-29.8,66.6-66.6S175.3,542.8,138.5,542.8'
                />
                <path
                  class='st2' d='M104.4,599.8l32.9-18.9c0.8-0.5,1.8-0.5,2.7,0l32.9,19c1.3,0.7,1.7,2.4,1,3.6c-0.5,0.9-1.4,1.3-2.3,1.3c-0.5,0-0.9-0.1-1.3-0.4l-31.5-18.2L107,604.4c-1.3,0.7-2.9,0.3-3.6-1C102.6,602.2,103.1,600.6,104.4,599.8'
                />
                <path
                  class='st2' d='M151.4,625.2L129.2,638c-0.3,0.2-0.6,0.2-0.9,0.2c-0.2,0-0.3,0-0.5-0.1l-23.4-6.3c-0.8-0.2-1.4-0.9-1.4-1.8v-13.3c0-0.7,0.4-1.3,0.9-1.6l15-8.7c2.5-1.4,4.8,3,0.9,6.9l-5.6,5.5c-0.3,0.3-0.5,0.8-0.5,1.3c0,0.7,0.4,1.3,0.9,1.6l13.1,7.6c0.3,0.2,0.6,0.2,0.9,0.2c0.2,0,0.4,0,0.6-0.1l15.7-5.7C151.7,621.5,152.6,624.5,151.4,625.2'
                />
                <path
                  class='st2' d='M174.1,627.7c0,0.7-0.4,1.3-0.9,1.6l-15,8.7c-2.5,1.4-4.8-3-0.9-6.9l5.6-5.5c0.3-0.3,0.5-0.8,0.5-1.3c0-0.7-0.4-1.3-0.9-1.6l-13.1-7.6c-0.3-0.2-0.6-0.2-0.9-0.2c-0.2,0-0.4,0-0.6,0.1l-15.7,5.7c-6.7,2.4-7.6-0.6-6.4-1.3l22.2-12.8c0.3-0.2,0.6-0.2,0.9-0.2c0.2,0,0.3,0,0.5,0.1l23.4,6.3c0.8,0.2,1.4,0.9,1.4,1.8V627.7z'
                />
              </g>
              <g>
                <text transform='matrix(1 0 0 1 193.5552 689.9131)'><tspan x='0' y='0' class='st8 st5'>Apoyo y </tspan><tspan x='-28.7' y='36' class='st8 st5'>seguimiento</tspan></text>
              </g>
            </g>
          </Link>

          <Link to='/servicios/asesoramiento-territorial'>
            <g id='asesoramiento' class='itemRueda'>
              <linearGradient id='SVGID_6_' gradientUnits='userSpaceOnUse' x1='311.3496' y1='176.9051' x2='730.0715' y2='470.0973'>
                <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
                <stop offset='1' style={{ stopColor: '#808080' }} />
              </linearGradient>
              <path class='st11' d='M637.5,89.4c-93.4,0.2-173.2,21.7-254.2,68.5c-97.9,56.5-170,140.6-212.2,236.7l467.5,201.7L637.5,89.4z' />
              <g>
                <path
                  class='st1' d='M351.7,111.9c-40,0-72.4,32.4-72.4,72.4s32.4,72.4,72.4,72.4c40,0,72.4-32.4,72.4-72.4S391.6,111.9,351.7,111.9'
                />
                <path
                  class='st2' d='M385,205c0,0.8-0.6,1.4-1.4,1.4h-42.5c-0.8,0-1.4-0.6-1.4-1.4v-57.9c0-0.8,0.6-1.4,1.4-1.4h42.5c0.8,0,1.4,0.6,1.4,1.4V205z'
                />
                <path
                  class='st2' d='M363.7,221.4c0,0.8-0.6,1.4-1.4,1.4h-42.5c-0.8,0-1.4-0.6-1.4-1.4v-57.9c0-0.8,0.6-1.4,1.4-1.4h16.9v8.2h-10.1v5.8h10.1v2.9h-10.1v5.8h10.1V205c0,2.4,1.9,4.3,4.3,4.3h22.7V221.4z'
                />
                <rect x='342.5' y='148.6' class='st1' width='39.6' height='55' />
                <rect x='347.8' y='195.2' class='st2' width='10.1' height='2.9' />
                <rect x='347.8' y='189.4' class='st2' width='20.3' height='2.9' />
                <rect x='347.8' y='183.6' class='st2' width='20.3' height='2.9' />
                <rect x='347.8' y='162.5' class='st2' width='20.3' height='5.8' />
                <rect x='347.8' y='153.9' class='st2' width='28.9' height='5.8' />
                <rect x='326.6' y='212.2' class='st1' width='9.6' height='2.9' />
                <rect x='279.3' y='111.9' class='st3' width='144.7' height='144.7' />
              </g>
              <g>
                <text transform='matrix(1 0 0 1 294.4102 300.9761)'><tspan x='0' y='0' class='st4 st5'>Asesoramiento</tspan><tspan x='32' y='36' class='st4 st5'>en Acceso</tspan></text>
              </g>
            </g>
          </Link>

          <polyline class='st12' points='170.9,394.5 638.5,596.3 637.5,84.7 ' />
          <polygon class='st3' points='636.6,601.7 637.1,600.3 636.6,601.7 ' />
          <linearGradient id='SVGID_7_' gradientUnits='userSpaceOnUse' x1='550.0286' y1='1111.5605' x2='550.0286' y2='600.4321'>
            <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
            <stop offset='1' style={{ stopColor: '#FDBB31' }} />
          </linearGradient>
          <path class='st13' d='M636.6,601.7l-173.1,478.8c0.1,0,0.2,0.1,0.2,0.1L636.6,601.7L636.6,601.7z' />
          <polygon class='st14' points='638.1,600.8 637.1,600.3 637.4,601.2 ' />
          <linearGradient id='SVGID_8_' gradientUnits='userSpaceOnUse' x1='812.4236' y1='1111.5605' x2='812.4236' y2='797.1074'>
            <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
            <stop offset='1' style={{ stopColor: '#FDBB31' }} />
          </linearGradient>
          <path class='st15' d='M812.4,1079.4c0,0,0.1,0,0.1,0C812.4,1079.4,812.4,1079.4,812.4,1079.4L812.4,1079.4z' />
          <path class='st14' d='M463.7,1080.9c12.8,4.6,25.8,8.7,38.8,12.2c-13-3.6-25.9-7.7-38.7-12.4L463.7,1080.9z' />
          <polygon class='st14' points='637.4,601.2 637.1,600.3 636.6,601.7 ' />
          <linearGradient id='SVGID_9_' gradientUnits='userSpaceOnUse' x1='813.4771' y1='1111.5605' x2='813.4771' y2='600.4321'>
            <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
            <stop offset='1' style={{ stopColor: '#FDBB31' }} />
          </linearGradient>
          <path class='st16' d='M814.5,1078.6L814.5,1078.6c-0.7,0.2-1.3,0.5-2,0.7C813.1,1079.1,813.8,1078.9,814.5,1078.6z' />
          <polygon class='st14' points='637.4,601.2 637.1,600.3 637.4,601.2 ' />
          <linearGradient id='SVGID_10_' gradientUnits='userSpaceOnUse' x1='725.9539' y1='1111.5605' x2='725.9539' y2='600.4321'>
            <stop offset='0' style={{ stopColor: '#FFFFFF' }} />
            <stop offset='1' style={{ stopColor: '#FDBB31' }} />
          </linearGradient>
          <path class='st17' d='M637.4,601.2l174.9,478.2c0,0,0.1,0,0.1,0c0.7-0.2,1.3-0.5,2-0.8L637.4,601.2L637.4,601.2z' />
          <circle class='st18' cx='638.8' cy='600.5' r='287.2' />
          <rect x='253.3' y='1065.2' class='st3' width='48.9' height='48.9' />
          <g>
            <circle class='st2' cx='638.8' cy='600.5' r='239.2' />
            <circle class='st19' cx='638.8' cy='600.5' r='226.5' />
            <g>
              <g>
                <g>
                  <path
                    class='st2' d='M498.5,612.7c0,3.8,0.6,6.2,1.7,7.2c1.1,1,3.7,1.5,7.7,1.5h2.9v13.2h-43.1v-13.2h4.9c4,0,6.6-0.5,7.7-1.5c1.1-1,1.6-3.4,1.6-7.2v-56.9c0-3.8-0.5-6.2-1.6-7.2c-1.1-1-3.7-1.5-7.7-1.5h-4.9v-13.2h52.9l17.5,62l18.5-62h52.4V547h-5.1c-4,0-6.5,0.5-7.6,1.5c-1.1,1-1.7,3.4-1.7,7.2v56.9c0,3.8,0.6,6.2,1.7,7.2c1.1,1,3.7,1.5,7.6,1.5h5.1v13.2H556v-13.2h3c3.9,0,6.4-0.5,7.5-1.5c1.1-1,1.7-3.4,1.7-7.3v-62.9l-25.1,84.9h-20.2l-24.4-85V612.7z'
                  />
                  <path
                    class='st2' d='M609.8,634.6v-13.2h3.7c3.3,0,5.7-0.5,7.2-1.6c1.5-1.1,3-3.8,4.5-8.2l25.9-77.8h30l24.8,78.3c1.3,3.8,2.6,6.3,4,7.5c1.4,1.2,3.5,1.8,6.3,1.8h5.1v13.2h-55.6v-13.2h4.3c3.6,0,6-0.3,7.3-0.8c1.2-0.5,1.9-1.5,1.9-2.8c-0.1-0.4-0.2-1.1-0.5-2.1c-0.1-0.5-0.2-0.9-0.3-1.1l-3.9-12.4h-29l-3.8,11.6c-0.2,0.7-0.4,1.4-0.5,2.1c-0.1,0.7-0.2,1.4-0.2,1.9c0,1.4,0.5,2.3,1.5,2.8c1,0.5,3.2,0.8,6.6,0.8h4.9v13.2H609.8z M670.2,589.1l-10.1-31.8l-10.3,31.8H670.2z'
                  />
                  <path
                    class='st2' d='M737.8,635.9h-12.2v-39.6h13.5c1.2,8.1,4.7,14.5,10.3,19.3c5.7,4.8,12.6,7.2,20.9,7.2c5.7,0,10.1-1.1,13.3-3.2c3.2-2.2,4.8-5.1,4.8-8.8c0-5.8-6-10.1-18-12.9c-2-0.4-3.5-0.8-4.5-1c-10.5-2.5-17.8-4.5-21.8-6.2c-4-1.6-7.4-3.5-10-5.6c-3.4-2.6-5.9-5.8-7.7-9.5c-1.7-3.7-2.6-7.7-2.6-12.2c0-10.2,3.6-18.1,10.7-23.7c7.2-5.6,17.3-8.4,30.3-8.4c6.5,0,12.3,0.7,17.3,2.1c5,1.4,9.4,3.5,13,6.3l3-7.1h12.5v36.8h-13.8c-0.9-7.4-4-13.3-9.2-17.5c-5.3-4.2-12.1-6.3-20.5-6.3c-5,0-9,1.1-12,3.2c-2.9,2.1-4.4,4.9-4.4,8.5c0,5.1,6.6,9.1,19.9,11.9c5,1.1,9,2,12,2.7c11.5,2.8,20,6.9,25.3,12.2c5.4,5.3,8,12.2,8,20.7c0,10-3.8,18-11.3,23.9c-7.5,5.9-17.7,8.9-30.5,8.9c-6.2,0-12.1-0.8-18-2.5c-5.8-1.6-10.7-3.8-14.5-6.6L737.8,635.9z'
                  />
                </g>
              </g>
            </g>
          </g>
          <g class='textCentral'>
            <rect x='288.1' y='661.2' class='st3' width='693' height='94.1' />
            <text transform='matrix(1 0 0 1 462.6846 678.3525)'><tspan x='0' y='0' class='st20 st21 st22'>MEJORANDO EL ACCESO Y LA </tspan><tspan x='-7.7' y='29' class='st20 st21 st22'>ADHERENCIA AL TRATAMIENTO</tspan></text>
          </g>
          <g>
            <rect x='469' y='480.6' class='st3' width='353.5' height='62.4' />
            <text transform='matrix(1 0 0 1 468.9609 523.2124)' class='st20 st4 st23 st24'>PROGRAMA</text>
          </g>
          <g>
            <g>
              <path d='M498,836.5l12.5-21.2l3.5,2.1l-12.5,21.2L498,836.5z' />
              <path d='M505.7,841l11.3-21.9l3.5,1.8l-0.2,18.4l7.6-14.6l3.4,1.7l-11.3,21.9l-3.6-1.9l0.1-18l-7.4,14.3L505.7,841z' />
              <path d='M524.5,850.4l10.1-22.5l3.7,1.7l-10.1,22.5L524.5,850.4z' />
              <path d='M548,850.3l3.1,2.8c-1.6,2.4-3.2,3.9-5,4.5s-3.7,0.6-5.6-0.1c-2.5-0.9-4.1-2.7-4.9-5.1c-0.9-2.9-0.7-6.2,0.8-9.9c1.5-3.9,3.6-6.7,6.2-8.2c2.3-1.3,4.7-1.5,7.3-0.5c2.1,0.8,3.6,2.2,4.5,4.2c0.6,1.4,0.8,3.2,0.5,5.4l-4.2-0.3c0.2-1.3,0.1-2.5-0.3-3.4s-1.2-1.6-2.2-2c-1.4-0.5-2.8-0.4-4.1,0.5c-1.3,0.9-2.6,2.7-3.6,5.5c-1.1,2.9-1.5,5.1-1.1,6.7c0.4,1.6,1.3,2.6,2.6,3.1c1,0.4,2,0.3,3.1-0.2C546,852.9,547,851.9,548,850.3z' />
              <path d='M552.4,861.4L560,838l3.9,1.2l-7.6,23.5L552.4,861.4z' />
              <path d='M563.2,852.2c0.7-2.8,1.8-5.1,3.1-6.7s2.8-2.7,4.4-3.3s3.4-0.6,5.3-0.1c2.8,0.7,4.8,2.4,6,5c1.2,2.6,1.3,5.9,0.3,9.8c-1,4-2.8,6.9-5.2,8.6c-2.2,1.6-4.6,2-7.4,1.2c-2.8-0.7-4.8-2.3-5.9-4.7C562.3,859.5,562.2,856.1,563.2,852.2z M567.3,853.1c-0.7,2.7-0.7,4.9,0,6.6c0.7,1.6,1.8,2.7,3.3,3.1c1.6,0.4,3,0.1,4.4-1c1.4-1.1,2.4-3.1,3.2-5.9c0.7-2.8,0.8-4.9,0.1-6.5c-0.7-1.6-1.8-2.6-3.4-3s-3.1-0.1-4.4,1S568,850.3,567.3,853.1z' />
              <path d='M595.2,846.9l7.4,1c1.9,0.3,3.2,0.7,4.1,1.2c0.9,0.6,1.7,1.4,2.3,2.5c0.7,1.1,1.1,2.5,1.3,4.1c0.2,1.6,0.2,3.6-0.2,6c-0.3,2.1-0.8,3.9-1.5,5.4c-0.7,1.5-1.5,2.6-2.3,3.4c-0.9,0.8-1.8,1.4-2.9,1.7s-2.5,0.4-4.1,0.1l-7.6-1.1L595.2,846.9zM598.6,851.6l-2.3,16.1l3,0.4c1.3,0.2,2.3,0.2,2.9,0s1.1-0.5,1.6-0.9c0.4-0.4,0.9-1.2,1.3-2.2c0.4-1,0.7-2.4,1-4.2c0.3-1.8,0.3-3.2,0.2-4.3c-0.1-1.1-0.4-2-0.8-2.6c-0.4-0.7-1-1.2-1.8-1.5c-0.5-0.2-1.6-0.5-3.2-0.7L598.6,851.6z' />
              <path d='M613.2,874.2l1.6-24.6l15,1l-0.3,4.2l-10.9-0.7l-0.4,5.5l10.1,0.7l-0.3,4.1l-10.1-0.7l-0.4,6.7l11.3,0.7l-0.3,4.2L613.2,874.2z' />
              <path d='M645.9,875.2l-0.7-20.5l-6,0.2l-0.1-4.2l16-0.5l0.1,4.2l-6,0.2l0.7,20.5L645.9,875.2z' />
              <path d='M659.8,874.6l-2.6-24.5l8.5-0.9c2.2-0.2,3.8-0.2,4.8,0.2c1,0.3,1.8,1,2.5,2c0.7,1,1.2,2.3,1.3,3.8c0.2,1.9-0.1,3.5-0.9,4.8c-0.8,1.3-2,2.1-3.7,2.6c0.9,0.5,1.7,1.1,2.4,1.8s1.6,1.9,2.7,3.7l2.9,4.5l-4.8,0.5l-3.5-5c-1.3-1.8-2.1-2.9-2.5-3.4c-0.4-0.4-0.9-0.7-1.3-0.9c-0.4-0.1-1.1-0.2-2.1-0.1l-0.8,0.1l1.1,10.2L659.8,874.6z M662.3,860l3-0.3c1.8-0.2,3-0.4,3.5-0.6c0.5-0.2,0.9-0.6,1.1-1.1s0.3-1.2,0.3-2c-0.1-0.8-0.3-1.4-0.7-1.8c-0.4-0.4-0.8-0.7-1.4-0.8c-0.4-0.1-1.5,0-3.3,0.2l-3.2,0.3L662.3,860z' />
              <path d='M699.2,868.8l-4.3,0.8l-2.8-5.2l-8,1.5l-0.6,5.8l-4.3,0.8l3.2-25.7l4.3-0.8L699.2,868.8z M690,860.5l-4.4-8.5l-1,9.5L690,860.5z' />
              <path d='M704.7,867.3l-5-19.8l-5.8,1.5l-1-4l15.6-4l1,4l-5.8,1.5l5,19.8L704.7,867.3z' />
              <path d='M733.3,858.7l-4.2,1.4l-3.4-4.8l-7.7,2.5l0.1,5.8L714,865l-0.1-25.9l4.1-1.3L733.3,858.7z M723.2,851.8l-5.5-7.8l0.2,9.5L723.2,851.8z' />
              <path d='M736.6,857.5l-9.6-22.7l5.6-2.4l9.9,14.1l-3.2-16.9l5.6-2.4l9.6,22.7l-3.5,1.5l-7.6-17.9l3.8,19.5l-3.6,1.5l-11.2-16.3l7.6,17.9L736.6,857.5z' />
              <path d='M759,847.6l-11-22.1l3.6-1.8l11,22.1L759,847.6z' />
              <path d='M766.9,843.7l-12.1-21.5l13.1-7.4l2,3.6l-9.5,5.4l2.7,4.8l8.9-5l2,3.6l-8.9,5l3.3,5.8l9.8-5.5l2,3.6L766.9,843.7z' />
              <path d='M784.3,833.7l-13.7-20.5l3.3-2.2l16,9.1l-9.1-13.7l3.2-2.1l13.7,20.5l-3.4,2.3l-15.7-8.9l8.9,13.4L784.3,833.7z' />
              <path d='M805.7,818.8l-12.6-16.2l-4.7,3.7l-2.6-3.3l12.7-9.9l2.6,3.3l-4.7,3.7l12.6,16.2L805.7,818.8z' />
              <path d='M807.4,802c-2-2.2-3.3-4.3-3.9-6.3c-0.6-2-0.7-3.8-0.3-5.4c0.4-1.6,1.4-3.1,2.8-4.4c2.1-1.9,4.6-2.7,7.5-2.3c2.8,0.4,5.6,2.1,8.3,5.2c2.8,3.1,4.2,6.1,4.3,9.1c0.1,2.7-0.9,5-3.1,6.9c-2.2,2-4.6,2.8-7.2,2.4C813,806.7,810.1,805,807.4,802zM810.4,799c1.9,2.1,3.7,3.3,5.5,3.7c1.8,0.3,3.2,0,4.4-1.1c1.2-1.1,1.7-2.5,1.5-4.2c-0.2-1.8-1.2-3.7-3.2-5.9c-1.9-2.1-3.7-3.3-5.4-3.7c-1.7-0.3-3.1,0.1-4.4,1.2c-1.2,1.1-1.8,2.5-1.6,4.3C807.4,795,808.5,796.9,810.4,799z' />
            </g>
          </g>
          <g>
            <path d='M782.7,394.9l14.7-19.8l5.3,3.9c2,1.5,3.2,2.5,3.6,3.1c0.7,1,1,2.3,0.9,3.7c-0.1,1.5-0.8,3-1.9,4.6c-1.1,1.4-2.2,2.4-3.5,3c-1.3,0.6-2.4,0.7-3.5,0.5c-1.1-0.2-2.6-1.1-4.6-2.5l-2.1-1.6l-5.6,7.5L782.7,394.9z M798.3,381l-4.2,5.6l1.8,1.4c1.2,0.9,2.1,1.4,2.7,1.6c0.6,0.2,1.2,0.1,1.8-0.1c0.6-0.2,1.2-0.7,1.6-1.3c0.5-0.7,0.8-1.3,0.8-2c0-0.7-0.1-1.2-0.4-1.7c-0.3-0.5-1.1-1.2-2.5-2.2L798.3,381z' />
            <path d='M797.4,406l16.2-18.6l6.5,5.6c1.7,1.4,2.7,2.6,3.1,3.6c0.4,1,0.5,2,0.2,3.3c-0.3,1.2-0.9,2.4-1.9,3.6c-1.3,1.5-2.6,2.3-4.1,2.6c-1.5,0.3-2.9,0-4.4-0.9c0.2,1,0.3,2,0.3,3c-0.1,0.9-0.4,2.5-0.9,4.6l-1.3,5.2l-3.7-3.2l1.3-6c0.5-2.2,0.7-3.5,0.8-4.2c0-0.6,0-1.1-0.2-1.6c-0.2-0.4-0.7-0.9-1.4-1.6l-0.6-0.6l-6.8,7.8L797.4,406z M809.8,398l2.3,2c1.4,1.2,2.3,1.9,2.8,2.1c0.5,0.2,1,0.2,1.6,0.1c0.6-0.2,1.1-0.6,1.6-1.2c0.5-0.6,0.8-1.2,0.9-1.7c0.1-0.6,0-1.1-0.3-1.6c-0.2-0.3-1-1.1-2.4-2.3l-2.4-2.1L809.8,398z' />
            <path d='M812.3,419.2l17.6-17.3l10.5,10.7l-3,2.9l-7.7-7.8l-3.9,3.8l7.1,7.2l-3,2.9l-7.1-7.2l-4.8,4.7l7.9,8.1l-3,2.9L812.3,419.2z' />
            <path d='M829.3,427.4l3.5-3.1l5,5.7l-3.5,3.1L829.3,427.4z' />
            <path d='M834,443l16-12.7l-3.7-4.7l3.3-2.6l10,12.6l-3.3,2.6l-3.7-4.7l-16,12.7L834,443z' />
            <path d='M841.6,452.6l20.4-13.9l4.8,7.1c1.2,1.8,1.9,3.2,2.1,4.3c0.2,1,0,2.1-0.6,3.2c-0.6,1.1-1.5,2.1-2.7,3c-1.6,1.1-3.1,1.6-4.6,1.5s-2.8-0.8-4.1-2c0,1.1-0.2,2.1-0.5,3c-0.3,0.9-1,2.3-2,4.2l-2.6,4.7l-2.7-4l2.8-5.5c1-2,1.6-3.3,1.8-3.8c0.2-0.6,0.2-1.1,0.2-1.6c-0.1-0.5-0.4-1.1-0.9-1.9l-0.5-0.7l-8.5,5.8L841.6,452.6z M855.6,448l1.7,2.5c1,1.5,1.8,2.5,2.2,2.8c0.4,0.3,0.9,0.5,1.5,0.5s1.2-0.3,1.9-0.7c0.6-0.4,1.1-0.9,1.3-1.5s0.2-1.1,0.1-1.6c-0.1-0.4-0.7-1.3-1.7-2.8l-1.8-2.6L855.6,448z' />
            <path d='M861.7,485l-2.2-3.8l4-4.3l-4-7l-5.7,1.3l-2.2-3.8l25.3-5.4l2.1,3.8L861.7,485z M866.4,473.6l6.6-7l-9.3,2.2L866.4,473.6z' />
            <path d='M863.4,488.8l18.4-9l-2.6-5.4l3.7-1.8l7,14.4l-3.7,1.8l-2.6-5.4l-18.4,9L863.4,488.8z' />
            <path d='M874.8,514.9l-1.7-4.1l4.5-3.8l-3.1-7.5l-5.8,0.6l-1.6-4l25.8-2l1.6,4L874.8,514.9z M881,504.3l7.4-6l-9.5,0.9L881,504.3z' />
            <path d='M875.4,516.4l23.5-7.3l1.8,5.8l-15,8.5l17.2-1.5l1.8,5.9l-23.5,7.3l-1.1-3.6l18.5-5.8l-19.7,1.9l-1.2-3.7l17.4-9.6l-18.5,5.8L875.4,516.4z' />
            <path d='M881.9,538.6l23.9-5.9l1,4l-23.9,5.9L881.9,538.6z' />
            <path d='M883.8,546l24.2-4.6l2.8,14.7l-4.1,0.8l-2-10.7l-5.4,1l1.9,10l-4.1,0.8l-1.9-10l-6.6,1.3l2.1,11.1l-4.1,0.8L883.8,546z' />
            <path d='M887.1,564.1l24.5-2.8l0.4,3.9l-15.4,10.1l16.4-1.8l0.4,3.8l-24.5,2.8l-0.5-4.1l15.1-9.9l-16,1.8L887.1,564.1z' />
            <path d='M889.3,588.5l20.5-0.8l-0.2-6l4.2-0.2l0.6,16l-4.2,0.2l-0.2-6l-20.5,0.8L889.3,588.5z' />
            <path d='M901.9,599.8c2.9,0.1,5.3,0.6,7.2,1.5c1.9,0.9,3.2,2.1,4.1,3.5c0.9,1.4,1.3,3.1,1.2,5.1c-0.1,2.9-1.3,5.2-3.6,7c-2.3,1.7-5.4,2.5-9.5,2.4c-4.1-0.1-7.3-1.2-9.6-3.2c-2-1.8-2.9-4.1-2.8-7c0.1-2.9,1.2-5.2,3.3-6.8C894.7,600.5,897.9,599.7,901.9,599.8z M902,604c-2.8-0.1-5,0.4-6.4,1.4c-1.5,1-2.2,2.3-2.3,3.9c-0.1,1.6,0.6,3,2,4.1c1.4,1.1,3.5,1.7,6.4,1.8c2.9,0.1,5-0.3,6.4-1.3c1.4-1,2.1-2.3,2.2-4s-0.6-3-1.9-4.1C907,604.7,904.8,604.1,902,604z' />
            <path class='st3' d='M772.9,812.5l13,20.9l-3.5,2.2l-13-20.9L772.9,812.5z' />
            <path
              class='st3' d='M766.4,816.6l11.9,21.6l-3.5,1.9l-15.2-10.4l7.9,14.4l-3.3,1.8l-11.9-21.6l3.6-2l14.9,10.2l-7.7-14.1L766.4,816.6z'
            />
            <path class='st3' d='M748.8,825.9l10.7,22.2l-3.7,1.8l-10.7-22.2L748.8,825.9z' />
            <path
              class='st3' d='M733.5,842.6l-4.2,0.1c-0.5-2.8-0.3-5,0.5-6.8c0.8-1.7,2.2-3,4.2-3.8c2.4-1,4.8-0.9,7.1,0.4c2.6,1.4,4.7,4,6.2,7.7c1.6,3.9,2,7.3,1.1,10.2c-0.7,2.5-2.4,4.3-5,5.4c-2.1,0.9-4.1,0.9-6.1,0c-1.4-0.6-2.8-1.8-4.1-3.6l3.3-2.6c0.7,1.1,1.6,1.9,2.6,2.2c1,0.4,2,0.3,2.9-0.1c1.4-0.6,2.3-1.6,2.6-3.2c0.4-1.6,0-3.7-1.2-6.5c-1.2-2.9-2.5-4.7-3.8-5.6c-1.4-0.9-2.7-1-4.1-0.5c-1,0.4-1.7,1.2-2.1,2.2S733.1,840.8,733.5,842.6z'
            />
            <path class='st3' d='M723.5,836.6l8.1,23.3l-3.8,1.3l-8.1-23.3L723.5,836.6z' />
            <path
              class='st3' d='M720.4,850.7c0.8,2.8,1.1,5.3,0.7,7.3s-1,3.7-2.1,5c-1.1,1.3-2.6,2.2-4.5,2.7c-2.8,0.8-5.3,0.4-7.7-1.2c-2.4-1.6-4.1-4.4-5.2-8.3c-1.1-4-1.1-7.3,0.1-10.1c1.1-2.4,3-4.1,5.8-4.9c2.8-0.8,5.3-0.5,7.5,1C717.5,844,719.3,846.8,720.4,850.7z M716.4,852c-0.8-2.7-1.9-4.6-3.3-5.7c-1.4-1.1-2.9-1.4-4.4-0.9c-1.5,0.4-2.6,1.5-3.3,3.1c-0.6,1.6-0.5,3.9,0.3,6.7c0.8,2.7,1.9,4.6,3.2,5.7c1.4,1,2.8,1.3,4.4,0.9s2.7-1.5,3.3-3.1C717.3,857,717.2,854.8,716.4,852z'
            />
            <path
              class='st3' d='M692.1,870.9l-7.3,1.2c-1.9,0.3-3.3,0.3-4.3,0.1c-1-0.3-2-0.8-3-1.7c-1-0.9-1.8-2-2.5-3.5s-1.3-3.4-1.6-5.7c-0.3-2.1-0.4-4-0.2-5.6c0.2-1.6,0.6-3,1.2-4s1.3-1.9,2.3-2.5c1-0.6,2.2-1.1,3.9-1.3l7.6-1.3L692.1,870.9z M687.4,867.4l-2.7-16.1l-3,0.5c-1.3,0.2-2.2,0.5-2.8,0.8c-0.5,0.3-1,0.8-1.2,1.3s-0.5,1.4-0.5,2.5c-0.1,1.1,0,2.5,0.3,4.3c0.3,1.8,0.7,3.2,1.1,4.2c0.4,1,0.9,1.8,1.6,2.3c0.6,0.5,1.3,0.8,2.1,0.9c0.6,0,1.7-0.1,3.3-0.3L687.4,867.4z'
            />
            <path
              class='st3' d='M668.4,849.7l2.2,24.6l-14.9,1.3l-0.4-4.2l10.9-1l-0.5-5.4l-10.1,0.9l-0.4-4.1l10.1-0.9l-0.6-6.7l-11.3,1l-0.4-4.2L668.4,849.7z'
            />
            <path class='st3' d='M637.9,851.3l-0.3,20.5l6,0.1l-0.1,4.2l-16.1-0.3l0.1-4.2l6,0.1l0.3-20.5L637.9,851.3z' />
            <path
              class='st3' d='M625.5,851.1l-2.1,24.6l-8.6-0.7c-2.2-0.2-3.7-0.5-4.6-1.1c-0.9-0.5-1.6-1.4-2.1-2.5c-0.5-1.1-0.7-2.5-0.6-4c0.2-1.9,0.8-3.4,1.8-4.5c1-1.1,2.4-1.7,4.1-1.9c-0.8-0.7-1.5-1.4-2-2.2c-0.5-0.8-1.2-2.2-2-4.2l-2-5l4.9,0.4l2.5,5.6c0.9,2,1.5,3.3,1.9,3.8c0.3,0.5,0.7,0.9,1.1,1.1c0.4,0.2,1.1,0.4,2,0.4l0.8,0.1l0.9-10.3L625.5,851.1z M620.3,864.9l-3-0.3c-1.8-0.2-3-0.2-3.5-0.1c-0.5,0.1-1,0.4-1.3,0.9c-0.4,0.5-0.6,1.1-0.6,1.9c-0.1,0.8,0,1.4,0.3,1.9c0.3,0.5,0.7,0.9,1.2,1.1c0.4,0.2,1.5,0.3,3.3,0.5l3.2,0.3L620.3,864.9z'
            />
            <path
              class='st3' d='M587.7,846.3l4.4,0.7l0.8,5.8l8,1.3l2.6-5.2l4.3,0.7l-11.8,23l-4.3-0.7L587.7,846.3z M593.6,857.2l1.3,9.5l4.2-8.6L593.6,857.2z'
            />
            <path class='st3' d='M583.6,845.2l-4.6,19.9l5.8,1.4l-0.9,4.1l-15.6-3.6l0.9-4.1l5.8,1.4l4.6-19.9L583.6,845.2z' />
            <path
              class='st3' d='M556.2,837.6l4.2,1.3l0.1,5.9l7.7,2.3l3.2-4.9l4.2,1.3l-14.7,21.3l-4.1-1.3L556.2,837.6z M560.6,849.1l0,9.6l5.3-8L560.6,849.1z'
            />
            <path
              class='st3' d='M554.7,837.1l-9.2,22.9l-5.7-2.3l2.8-17l-9.6,14.3l-5.7-2.3l9.2-22.9l3.5,1.4l-7.2,18l11-16.5l3.6,1.5l-3.5,19.5l7.2-18L554.7,837.1z'
            />
            <path class='st3' d='M533.4,828.2l-10.5,22.3l-3.7-1.7l10.5-22.3L533.4,828.2z' />
            <path
              class='st3' d='M526.5,824.9l-11.7,21.7l-13.2-7.1l2-3.7l9.6,5.2l2.6-4.8l-8.9-4.8l2-3.7l8.9,4.8l3.2-5.9l-9.9-5.4l2-3.7L526.5,824.9z'
            />
            <path
              class='st3' d='M510.3,816.1L497,836.8l-3.3-2.1l1.9-18.3l-8.9,13.9l-3.2-2.1l13.3-20.7l3.5,2.2l-1.8,17.9l8.7-13.5L510.3,816.1z'
            />
            <path class='st3' d='M490,802.5l-12.3,16.4l4.8,3.6l-2.5,3.3l-12.8-9.6l2.5-3.3l4.8,3.6l12.3-16.4L490,802.5z' />
            <path
              class='st3' d='M473.2,805.1c-1.9,2.2-3.8,3.7-5.8,4.6c-1.9,0.9-3.7,1.2-5.4,1s-3.3-1-4.8-2.3c-2.2-1.9-3.2-4.3-3.1-7.1s1.5-5.8,4.1-8.9c2.7-3.1,5.6-4.9,8.5-5.4c2.6-0.4,5,0.3,7.2,2.2c2.2,1.9,3.3,4.2,3.2,6.8C477.3,799,475.9,802,473.2,805.1zM470,802.5c1.9-2.1,2.9-4.1,3-5.9c0.1-1.8-0.4-3.2-1.6-4.2c-1.2-1.1-2.7-1.4-4.4-1c-1.7,0.4-3.5,1.6-5.5,3.8c-1.9,2.2-2.9,4.1-3,5.8c-0.1,1.7,0.4,3.1,1.7,4.2s2.7,1.5,4.4,1.1C466.3,805.9,468.1,804.6,470,802.5z'
            />
            <path d='M402,516.9l-1.3,4.2l-5.9,0l-2.4,7.7l4.8,3.2l-1.3,4.1l-21.2-14.8l1.3-4.1L402,516.9z M390.5,521.2l-9.6,0l7.9,5.3L390.5,521.2z' />
            <path d='M379.7,506.2l2.8-6.9c0.7-1.7,1.4-3,2.2-3.7c0.7-0.7,1.7-1.3,3-1.7c1.2-0.4,2.7-0.5,4.3-0.3s3.6,0.7,5.8,1.6c2,0.8,3.6,1.7,4.9,2.7c1.3,1,2.2,2.1,2.8,3.1s0.9,2.1,0.9,3.3s-0.2,2.5-0.8,4l-2.8,7.1L379.7,506.2z M385.1,504l15.2,6l1.1-2.8c0.5-1.2,0.7-2.1,0.7-2.8c0-0.6-0.2-1.2-0.5-1.8c-0.3-0.5-0.9-1.1-1.8-1.7c-0.9-0.6-2.2-1.3-3.8-1.9c-1.7-0.7-3.1-1.1-4.2-1.2s-2-0.1-2.7,0.2c-0.8,0.3-1.4,0.7-1.8,1.4c-0.3,0.5-0.8,1.5-1.4,3L385.1,504z' />
            <path d='M410.1,496.8L388,485.9l1.8-3.6l8.7,4.3l3.6-7.2l-8.7-4.3l1.8-3.6l22.1,10.9l-1.8,3.6l-9.7-4.8l-3.6,7.2l9.7,4.8L410.1,496.8z' />
            <path d='M419.1,479.1l-21.2-12.6l7.6-12.9l3.6,2.1l-5.6,9.4l4.7,2.8l5.2-8.7l3.6,2.1l-5.2,8.7l5.8,3.4l5.8-9.7l3.6,2.1L419.1,479.1z' />
            <path d='M428.6,463.3l-20.2-14.1l4.9-7c1.3-1.8,2.3-3,3.2-3.5c0.9-0.5,2-0.7,3.2-0.6c1.2,0.1,2.5,0.6,3.7,1.5c1.6,1.1,2.6,2.4,3,3.8c0.4,1.4,0.3,2.9-0.4,4.5c1-0.4,2-0.6,2.9-0.6c0.9,0,2.5,0.1,4.6,0.4l5.3,0.8l-2.8,4l-6.1-0.7c-2.2-0.2-3.6-0.3-4.2-0.3c-0.6,0-1.1,0.2-1.5,0.4c-0.4,0.2-0.9,0.7-1.4,1.5l-0.5,0.7l8.4,5.9L428.6,463.3z M419.3,451.8l1.7-2.5c1.1-1.5,1.7-2.5,1.8-3c0.2-0.5,0.1-1-0.1-1.6s-0.7-1-1.3-1.5c-0.6-0.5-1.2-0.7-1.8-0.7s-1.1,0.1-1.5,0.5c-0.3,0.2-1,1.1-2.1,2.6l-1.8,2.6L419.3,451.8z' />
            <path d='M440.1,447.1l-19.1-15.6l9.5-11.6l3.2,2.6l-6.9,8.4l4.2,3.5l6.4-7.8l3.2,2.6l-6.4,7.8l5.2,4.2l7.2-8.7l3.2,2.6L440.1,447.1z' />
            <path d='M451.9,432.9l-17.8-17l2.7-2.9l17.6,5.4L442.6,407l2.6-2.7l17.8,17l-2.8,3l-17.2-5.2l11.6,11.1L451.9,432.9z' />
            <path d='M469.4,403.3l4-1.5c1.4,2.5,2,4.7,1.7,6.6c-0.2,1.9-1.1,3.6-2.7,5c-2,1.8-4.2,2.4-6.8,2c-3-0.5-5.8-2.2-8.4-5.2c-2.8-3.1-4.3-6.2-4.4-9.3c-0.1-2.6,0.8-4.9,2.9-6.7c1.7-1.5,3.6-2.2,5.8-2.1c1.5,0.1,3.2,0.8,5,2.1l-2.2,3.6c-1.1-0.8-2.1-1.2-3.2-1.3c-1,0-2,0.3-2.7,1c-1.1,1-1.6,2.3-1.4,3.9s1.2,3.5,3.2,5.7c2.1,2.3,3.9,3.7,5.4,4c1.6,0.4,2.9,0.1,4-0.9c0.8-0.7,1.2-1.6,1.2-2.8C470.8,406.3,470.3,404.9,469.4,403.3z' />
            <path d='M480.8,405.6l-15.4-19.3l3.2-2.5l15.4,19.3L480.8,405.6z' />
            <path d='M501.6,390.2l-3.6,2.6l-4.7-3.5l-6.6,4.7l1.9,5.5l-3.5,2.5l-7.9-24.6l3.5-2.5L501.6,390.2z M489.8,386.6l-7.6-5.8l3.1,9L489.8,386.6z' />
          </g>
        </svg>

      </div>

    </div>

  )
}

export default Home
