/* global Headers */
import { request } from './request'
import { envConfig } from '../config'
import Cookies from 'js-cookie'

const token = Cookies.get('auth_token')

const message = {
  error: 'Tuvimos en el servidor, vuelve a intentarlo mas tarde'
}

const Api = {
  async get (path, baseUrl = envConfig.baseUrlApi) {
    try {
      return await request(`${baseUrl}${path}`, {
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        })
      })
    } catch (error) {
      throw message.error
    }
  },

  async post (path, body = {}, baseUrl = envConfig.baseUrlApi) {
    try {
      return await request(`${baseUrl}${path}`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify(body)
      })
    } catch (error) {
      throw message.error
    }
  },

  async put (path, body = {}, baseUrl = envConfig.baseUrlApi) {
    try {
      return await request(`${baseUrl}${path}`, {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }),
        body: JSON.stringify(body)
      })
    } catch (error) {
      throw message.error
    }
  }
}

export default Api
