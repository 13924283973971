import React, { useState, useEffect, useRef } from 'react'
import { CusmtomLink } from './link'

export const LinkWithListLink = ({ onClick = () => { } }) => {
  const [show, setShow] = useState(false)
  const refLi = useRef(null)

  const toggleModal = () => {
    setShow(prev => !prev)
  }

  const onHandleClick = () => {
    toggleModal()
    onClick()
  }

  const handleClickOutside = (event) => {
    if (refLi && !refLi.current.contains(event.target)) {
      setShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <li
      ref={refLi}
      className={`menu-item menu-item-submenu menu-item-rel menu-item-open-dropdown ${show ? 'menu-item-hover menu-item-open' : ''}`}
      role='menuitem'
    >
      <span onClick={toggleModal} className='menu-link menu-toggle'>
        <span className='menu-text'>Servicios del programa</span>
      </span>

      <div className='menu-submenu menu-submenu-classNameic menu-submenu-right'>
        <ul className='menu-subnav'>
          <CusmtomLink activeOnlyWhenExact onClick={onHandleClick} to='/servicios' label='Home' />
          <CusmtomLink activeOnlyWhenExact onClick={onHandleClick} to='/servicios/aplicacion-en-centros' label='Servicios de centros de aplicación' />
          <CusmtomLink activeOnlyWhenExact onClick={onHandleClick} to='/servicios/aplicacion-en-domicilio' label='Servicio de enfermería' />
          <CusmtomLink activeOnlyWhenExact onClick={onHandleClick} to='/servicios/centro-de-infusion' label='Servicio de Centros de infusión' />
          <CusmtomLink activeOnlyWhenExact onClick={onHandleClick} to='/servicios/asesoramiento-territorial' label='Asesoramiento en Acceso' />
          <CusmtomLink activeOnlyWhenExact onClick={onHandleClick} to='/servicios/apoyo-y-seguimiento' label='Apoyo y seguimiento' />
        </ul>
      </div>
    </li>
  )
}
