/* eslint-disable */
import React, { useRef, useState } from 'react'
import Loading from '../Loading'
import { useForm } from 'react-hook-form'
import './styles.scss'
import { pacienteService } from '../../services/paciente.service'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const message = {
  require: 'Para continuar, completá este campo.',
  same: 'Las contraseñas no coinciden, deben ser iguales.',
  pass: 'La contraseña debe tener caracteres alfanumericos y al menos una mayúscula o una minúscula'
}

const initial = {
  password: '',
  repeatPassword: ''
}

const ChangePassword = (email) => {
  const { register, handleSubmit, errors, watch, reset } = useForm()
  const [regexError, setRegexError] = useState(false)
  const password = useRef({})
  password.current = watch('password', '')
  const [showButton, setShowButton] = useState(true)
  const [state, setstate] = useState({
    loading: false,
    error: false,
    success: false
  })

  const onSubmit = handleSubmit(async (data) => {
    setstate({
      loading: true,
      error: '',
      success: false
    })
    try {
      const loginOk = await pacienteService.login(email.email, data.actualPassword)
      if(loginOk.status === 'error'){
        toast.error('La contraseña actual es incorrecta')
        setstate(prev => ({
          ...prev,
          loading: false
        }))
        return false
      }
      const respose = await pacienteService.changePassword({
        password: data.password
      })
      setstate(prev => ({
        ...prev,
        loading: false,
        success: respose.message
      }))
      setShowButton(false)
      toast.success(respose.message)
      reset(initial)
    } catch (error) {
      setstate(prev => ({
        ...prev,
        loading: false,
        error: error?.message || error
      }))
    }
  })

  return (
    <form onSubmit={onSubmit}>
      <ToastContainer />
      <div className='card card-custom '>
        <div className='card-header py-3'>
          <div className='card-title align-items-start flex-column'>
            <h3 className='card-label font-weight-bolder text-dark'>Contraseña</h3>
            <span className='text-muted font-weight-bold font-size-sm mt-1'>Cambia tu contraseña desde aquí</span>
          </div>
          <div className='card-toolbar'>
            {
              showButton && <button type='submit' disabled={state.loading} className='btn btn-primary font-size-h6 px-6 py-3 mr-4'>{state.loading ? <Loading /> : 'Cambiar contraseña'}</button>
            }
          </div>
        </div>
        <div className='card-body'>
          <div className='form-group row' style={{ borderBottom: '1px solid rgb(213, 213, 213)' }}>
            <label className='col-xl-3 col-lg-3 col-form-label'>Contraseña actual</label>
            <div className='col-lg-9 col-xl-6'>
              <div>
                <input
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Escribe una nueva contraseña'
                  type='password'
                  name='actualPassword'
                  ref={register({ required: { value: true, message: message.require } })}
                />
                <p className='password-input__error'>{errors.actualPassword?.message}</p>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-xl-3 col-lg-3 col-form-label'>Nueva contraseña</label>
            <div className='col-lg-9 col-xl-6'>
              <div>
                <input
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Escribe una nueva contraseña'
                  type='password'
                  name='password'
                  ref={register({ required: { value: true, message: message.require }, minLength: { value: 8, message: 'La contraseña debe tener al menos 8 caracteres' }, pattern: { value: /(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9]{8,}/, message: message.pass } })}
                />
                <p className='password-input__error'>{errors.password?.message}</p>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <label className='col-xl-3 col-lg-3 col-form-label'>Repite tu contraseña</label>
            <div className='col-lg-9 col-xl-6'>
              <div>
                <input
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Repite tu nueva contraseña'
                  type='password'
                  name='repeatPassword'
                  ref={register({
                    required: { value: true, message: message.require },
                    validate: value => {
                      if (value !== password.current) {
                        return message.same
                      }
                      // if (!/(?=.*[A-Z])[a-zA-Z0-9]{8,}/.test(value)) {
                      //   return message.pass
                      // }
                    }

                  })}
                />
                <p className='password-input__error'>{errors.repeatPassword?.message}</p>
              </div>
            </div>
          </div>
          {state.error ? <p className='password-input__error'>{state.error}</p> : null}
          {/* {state.success ? <p className='password-input__success'>{state.success}</p> : null} */}
        </div>
      </div>
    </form>
  )
}

export default ChangePassword
